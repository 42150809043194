import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function TechnicalAnalysisWidget({
  containerRef,
  className=""
}) 
{
  const { i18n } = useTranslation();
  useEffect(() => {
    let isMounted = true;
    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
       interval: "1m",
       width: "100%",
       isTransparent: true,
       height: 450,
       symbol: "COINBASE:BTCUSD",
       showIntervalTabs: true,
       displayMode: "single",
       locale: `${i18n.language}`,
       colorTheme: "light"
    });

    const currentContainerRef = containerRef.current;

    if (currentContainerRef && isMounted){
      currentContainerRef.appendChild(script);
    }

    return () => {
      if ( currentContainerRef && currentContainerRef.contains(script) ){
        currentContainerRef.removeChild(script);
        isMounted = false;
      }
    }
  }, [containerRef])

  


  return (
    <>
       <div className={` ${className} rounded-[20px] bg-dark_blue-0 px-[0.38rem] py-[1.00rem] shadow-xs md:w-full md:p-[1.25rem] `}>
          <div ref={containerRef} className="w-full" ></div>
        </div>
    </>
  );
}
