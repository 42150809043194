import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-[26px]",
};
const variants = {
  fill: {
    blue_A700: "bg-blue-a700 shadow-sm text-dark_blue-1",
    dark_blue_0: "bg-dark_blue-0 text-dark_blue-1",
    dark_blue_1: "bg-dark_blue-1",
    black_900: "bg-black-900 shadow-sm text-dark_blue-1",
    gray_100: "bg-gray-100 text-black-900",
    my_green: "bg-my_green shadow-sm text-dark_blue-1",
    sky_blue: "bg-sky_blue text-black-900",
    primary: "bg-primary shadow-sm text-dark_blue-1",
  },
};
const sizes = {
  "3xl": "h-[4.88rem] px-[1.13rem] text-[1.88rem]",
  sm: "h-[2.38rem]",
  lg: "h-[3.25rem] px-[2.13rem] text-[1.25rem]",
  md: "h-[2.63rem] px-[2.13rem] text-[1.25rem]",
  xs: "h-[2.00rem] px-[2.13rem] text-[1.00rem]",
  "2xl": "h-[3.63rem] px-[2.13rem] text-[1.00rem]",
  xl: "h-[3.25rem] px-[2.13rem] text-[1.00rem]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xl",
  color = "primary",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["3xl", "sm", "lg", "md", "xs", "2xl", "xl"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf([
    "blue_A700",
    "dark_blue_0",
    "dark_blue_1",
    "black_900",
    "gray_100",
    "my_green",
    "sky_blue",
    "primary",
  ]),
};

export { Button };
