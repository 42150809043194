import useAuth from "hooks/zustand/useAuth";
import { Img, Heading } from "./..";
import React, { useEffect, useState } from "react";
import {
  SubMenu,
  MenuItem,
  Menu,
  Sidebar,
  sidebarClasses,
} from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import usePersonalInformation from "../../../hooks/zustand/usePersonalInformation";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Sidebar1({
    collapsed, 
    toggled, 
    handleToggledSidebar,
    handleCollapseSidebar,
    className,
  ...props }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userInfo = usePersonalInformation();
  const [theme] = useState("dark");
  const [hasImage] = useState(false);

  const handleLogout = () => {
    userInfo.reset();
    auth.logout();
    navigate(`/sign-in`);
  };

  const handleEmail = () => {
    navigate("/dashboard/email");
  };

  const handleChangePassword = () => {
    navigate("/dashboard/change-password");
  };

  const handleBankInformation = () => {
    navigate("/dashboard/bank-information");
  };

  useEffect(() => {
    console.log("collapsed", collapsed)
    if (userInfo?.user?._id === "") {
      userInfo.getData();
    }
  }, [userInfo]);

  return (
    <div className={`sidebar  ${className } ${toggled ? 'toggled' : ''} flex flex-col h-screen gap-[2.13rem] top-0 bg-dark_blue-0 !sticky overflow-auto sm:hidden`}>
      <div className="flex flex-col self-stretch rounded-br-[24px] rounded-tr-[24px] bg-dark_blue-0 px-[1.88rem] py-[1.38rem] sm:p-[1.25rem] ">
       <Link to="/dashboard">
       <Img
          src={process.env.REACT_APP_LOGO_DASHBOARD_PATH}
          alt="Sidebar Logo"
          className="h-[2.63rem] w-[11.25rem] object-contain"
         />
         </Link>
          <Heading
           size="header"
          as="h1"
           className="mt-[2.63rem] leading-[3.38rem] !text-sky_blue_2"
         >
           <span className="font-opensanshebrew text-[1.13rem] font-normal text-sky_blue_2">
            <>
             Welcome
               <br />
             </>
           </span>
           <span className="text-[1.88rem] font-bold text-sky_blue_2">
             {userInfo?.user?.status !== "missinginfo"
               ? userInfo?.user?.fullName || null
               : "N/A"}
           </span>
         </Heading>
         <div className="mb-[1.25rem] mt-[0.75rem] flex items-center justify-between gap-[1.25rem]">
           <Img
             src={`${process.env.REACT_APP_URL}images/img_lock.svg`}
             alt="Lock Icon"
             className="h-[1.13rem]"
             onClick={handleEmail}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_password.svg`}
             alt="Password Icon"
             className="h-[1.38rem] self-end"
             onClick={handleChangePassword}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_account_balance.svg`}
             alt="Balance Icon"
             className="h-[1.38rem]"
             onClick={handleBankInformation}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_logout.svg`}
             alt="Logout Icon"
             className="h-[1.38rem]"
             onClick={handleLogout}
           />
         </div>
      </div>
      <Menu
         menuItemStyles={{
            button: {
              padding: "0.38rem",
              color: "#ffffff",
              fontWeight: 700,
              fontSize: "1.13rem",
              borderRadius: "20px",
              [`&:hover, &.ps-active`]: {
               color: "#193261",
                backgroundColor: "#98bbff !important",
              },
            },  
            subMenuContent:({level})=>({
              backgroundColor:
                level===0
                ? hexToRgba(
                  themes[theme].menu.menuContent,
                  hasImage && !collapsed ? 0.4 : 1
                )
              : "transparent",
            })
          }}
          //rootStyles={{ ["&>ul"]: { gap: "1.88rem" } }}
          className="mb-[37.38rem] flex w-full flex-col self-stretch px-[1.38rem] sm:px-[1.25rem]"
        >
           <MenuItem
          icon={
            <Img
              src={`${process.env.REACT_APP_URL}images/img_home.svg`}
              alt="Home Icon"
              className="h-[1.50rem] w-[1.38rem]"
            />
          }
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          {t("member.menu.Dashboard")}
        </MenuItem>
        <MenuItem
          icon={
            <Img
              src={`${process.env.REACT_APP_URL}images/img_diamond.svg`}
              alt="Diamond Icon"
              className="h-[1.38rem] w-[1.38rem]"
            />
          }
          label="Investment"
        >
          Investment
        </MenuItem>
        <SubMenu
          label={`${t("member.menu.Crypto")}`}
          className="z-50 ml-4 overflow-hidden"
        >
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/btc");
            }}
          >
            {t("member.menu.Bitcoin")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/eth");
            }}
          >
            {t("member.menu.Ethereum")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/dot");
            }}
          >
            {t("member.menu.Polkadot")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/ht");
            }}
          >
            {t("member.menu.Houbi")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/xrp");
            }}
          >
            {t("member.menu.Ripple")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/doge");
            }}
          >
            {t("member.menu.Dogecoin")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/bnb");
            }}
          >
            {t("member.menu.Binancecoin")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/trx");
            }}
          >
            {t("member.menu.Tron")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/sol");
            }}
          >
            {t("member.menu.Solana")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/investment/usdt");
            }}
          >
            {t("member.menu.USDT")}
          </MenuItem>
        </SubMenu>
            <SubMenu
           label={`${t("member.menu.Share Index")}`}
          className="z-50 ml-4 overflow-hidden"
         >
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/sp500");
             }}
           >
             {t("member.menu.SP 500")}
           </MenuItem>
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/dxy");
            }}
           >
             {t("US Dollar Index")}
           </MenuItem>
           <MenuItem
            onClick={() => {
               navigate("/dashboard/investment/vix");
             }}
           >
             {t("Volatility Index")}
          </MenuItem>
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/dax");
             }}
           >
             {t("DAX Index")}
           </MenuItem>
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/hsi");
            }}
           >
             {t("Hang Seng Index")}
           </MenuItem>
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/cac40");
             }}
           >
             {t("CAC40 Index")}
           </MenuItem>
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/nikkei");
             }}
           >
             {t("Nikkei Index")}
           </MenuItem>
           <MenuItem
             onClick={() => {
               navigate("/dashboard/investment/nasdaq");
             }}
           >
             {t("Nasdaq Index")}
           </MenuItem>
         </SubMenu>
        <MenuItem
          icon={
            <Img
              src={`${process.env.REACT_APP_URL}images/img_home.svg`}
              alt="Home Icon"
              className="h-[1.50rem] w-[1.38rem]"
            />
          }
          onClick={() => {
            navigate("/dashboard/withdrawal");
          }}
        >
          {t("member.menu.Withdrawal")}
        </MenuItem>
    </Menu>
    </div>
    
  );
}

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const themes = {
  dark: {
    sidebar: {
      backgroundColor: "#202123",
      color: "white",
    },
    menu: {
      menuContent: "#202123",
      icon: "#fffff",
      hover: {
        backgroundColor: "#343541",
        color: "#fffff",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};