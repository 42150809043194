
import { WithGuard } from "middleware/route.guard";
import React , { lazy, Suspense } from "react";
import Dashboard from "../pages/Dashboard";
import { Route } from "react-router-dom";

const Withdrawal = lazy(() => import("../pages/Withdrawal"));
const WithdrawalHistory = lazy(()=> import("../pages/WithdrawalHistory"));
const DepositHistory = lazy(() => import("../pages/DepositHistory"));
const Email = lazy(() => import ("../pages/Email"));
const Password = lazy( ()=> import('../pages/Password'));
const BankInformation = lazy(() => import("../pages/BankInformation"));
const Investment = lazy(()=> import('../pages/Investment'));
const InvestmentDetail = lazy(() => import("../pages/Investment/InvestmentDetail"));

const DashboardGuard = WithGuard(Dashboard);
const WithdrawalGuard = WithGuard(Withdrawal);
const WithdrawalHistoryGuard = WithGuard(WithdrawalHistory);
const DepositHistoryGuard = WithGuard(DepositHistory);
const EmailGuard = WithGuard(Email);
const PasswordGuard = WithGuard(Password);
const BankInformationGuard = WithGuard(BankInformation);
const InvestmentGuard = WithGuard(Investment);
const InvestDetailGuard = WithGuard(InvestmentDetail);

const Loading = () => <div>Loading...</div>

export default [
   <Route  path="dashboard" element={<DashboardGuard />} />,
    <Route path='/dashboard/withdrawal' element={<Suspense fallback={<Loading />}>< WithdrawalGuard /></Suspense> }  />,
    <Route path="/dashboard/withdrawal-history" element={ <Suspense fallback={<Loading />}><WithdrawalHistoryGuard /></Suspense>  } />,
    <Route path='/dashboard/deposit-history' element={ <Suspense fallback={<Loading />}><DepositHistoryGuard /> </Suspense> } />,
    <Route path="/dashboard/email" element={ <Suspense fallback={ <Loading />}> <EmailGuard /> </Suspense> } />,
    <Route path='/dashboard/change-password' element={<Suspense fallback={<Loading />}> <PasswordGuard /> </Suspense>} />,
    <Route path='/dashboard/bank-information' element={<Suspense fallback={<Loading />}> <BankInformationGuard /> </Suspense>} />,
    <Route path='/dashboard/investment/investment-detail/:id' element={<Suspense fallback={<Loading />}><InvestDetailGuard/></Suspense>} />,
    <Route path="/dashboard/investment/:type" element={<Suspense fallback={<Loading />} > <InvestmentGuard /> </Suspense>} />,
   
]
