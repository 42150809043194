import React, { useEffect, useState } from "react";
import { Heading } from "../../components/theme2";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import TodayTrade from "../../components/theme2/card/Card.today-trade";
import { useTranslation } from "react-i18next";

export default function TodayTradeSection ({className}) 
{
    const userInfo = usePersonalInformation();
    const controller = new AbortController();
    const { t } = useTranslation();
    const [status, setStatus] = useState(userInfo?.user.status || process.env.REACT_APP_DEFAULT_USER_STATUS);

    async function initialize() {
        try{
            await userInfo.getData();
            await userInfo.getTodayTrade(controller.signal);
        }
        catch(err){
            console.log(err);
        }
        
      }
    useEffect(() => {
        if (userInfo?.user?._id === '' ){
            initialize();
        }
        setStatus(userInfo?.user?.status);
        console.log(userInfo?.todayTrade);
    }, [])

    return (
        <div className={`${className} lg:h-[482px] flex flex-1 flex-col  gap-[1.38rem] 
          self-center rounded-[20px] bg-dark_blue-0 px-[0.38rem] py-[0.75rem] 
          shadow-xs  `}>
            <div className="sm:h-[20rem]">
            <Heading as="h3" className="ml-[0.38rem] !text-sky_blue_2 md:ml-0">
                { t("member.dashboard.Today Trade") }
            </Heading>
            <div className="w-full overflow-hidden px-2">
            {(status !== 'missinginfo') ?
            userInfo?.todayTrade?.length > 0 && userInfo?.todayTrade?.map((a, b) => {
              return (
                // <Link key={b} to={`/dashboard/profile-detail/${a._id}`}>
                  <TodayTrade
                    member={userInfo?.user?.phoneNumber}
                    logo={a.product.coinId}
                    profile={`${a.product.coinId} ${a.profileDesc}`}
                    // profitLoss={a.profitLossAmount + a.investAmount}
                    investAmount={a.investAmount}
                    profitLoss={a.profitLossAmount}
                    changingTime={a.profitChangingTime}
                    percentage={(
                      (a.profitLossAmount / a.investAmount) *
                      100
                    ).toFixed(1)}
                    isProfit={a.isProfit}
                    isPlay={a.isPlay}
                    playDate={a.playDate}
                    finishPlayDate={a.finishPlayDate}
                  />
                // </Link>
                
              );
            }) : null}
            </div>
            </div>
            
        </div>
    )
}