import React, {useState} from "react";
import {
  SubMenu,
  MenuItem,
  Menu,
  Sidebar,
  sidebarClasses,
} from "react-pro-sidebar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Img, Heading } from "./..";

export default function SidebarMobile({
  isOpen ,
  setOpen
} ){
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [theme] = useState("dark");
  const [hasImage] = useState(false);
  return (
    <>
      <div   className={`fixed mt-[6.75rem] rounded-lg top-0 right-0 h-full w-[60%] bg-dark_blue-0 transform transition-transform duration-300 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}> 
       
      <div className="pt-10 ">
        <Menu
            menuItemStyles={{
                button: {
                  padding: "0.38rem",
                  color: "#ffffff",
                  fontWeight: 700,
                  fontSize: "1.13rem",
                  borderRadius: "20px",
                  [`&:hover, &.ps-active`]: {
                  color: "#193261",
                    backgroundColor: "#98bbff !important",
                  },
                },  
                subMenuContent:({level})=>({
                  backgroundColor:
                    level===0
                    ? hexToRgba(
                      themes[theme].menu.menuContent,
                      hasImage && !isOpen ? 0.4 : 1
                    )
                  : "transparent",
                })
              }}
              //rootStyles={{ ["&>ul"]: { gap: "1.88rem" } }}
              className="mb-[37.38rem] flex w-full flex-col self-stretch "
            >
              <MenuItem
              icon={
                <Img
                  src={`${process.env.REACT_APP_URL}images/img_home.svg`}
                  alt="Home Icon"
                  className="h-[1.50rem] w-[1.38rem] pb-10"
                />
              }
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              {t("member.menu.Dashboard")}
            </MenuItem>
            <MenuItem
              icon={
                <Img
                  src={`${process.env.REACT_APP_URL}images/img_diamond.svg`}
                  alt="Diamond Icon"
                  className="h-[1.38rem] w-[1.38rem] pb-5 "
                />
              }
              label="Investment"
            >
              Investment
            </MenuItem>
              <SubMenu
                label={`${t("member.menu.Crypto")}`}
                className="z-50 ml-10 overflow-hidden "
              >
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/btc");
                  }}
                >
                  {t("member.menu.Bitcoin")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/eth");
                  }}
                >
                  {t("member.menu.Ethereum")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/dot");
                  }}
                >
                  {t("member.menu.Polkadot")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/ht");
                  }}
                >
                  {t("member.menu.Houbi")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/xrp");
                  }}
                >
                  {t("member.menu.Ripple")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/doge");
                  }}
                >
                  {t("member.menu.Dogecoin")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/bnb");
                  }}
                >
                  {t("member.menu.Binancecoin")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/trx");
                  }}
                >
                  {t("member.menu.Tron")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/sol");
                  }}
                >
                  {t("member.menu.Solana")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/investment/usdt");
                  }}
                >
                  {t("member.menu.USDT")}
                </MenuItem>
              </SubMenu>
                <SubMenu
              label={`${t("member.menu.Share Index")}`}
              className="z-50 ml-10 overflow-hidden"
            >
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/sp500");
                }}
              >
                {t("member.menu.SP 500")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/dxy");
                }}
              >
                {t("US Dollar Index")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/vix");
                }}
              >
                {t("Volatility Index")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/dax");
                }}
              >
                {t("DAX Index")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/hsi");
                }}
              >
                {t("Hang Seng Index")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/cac40");
                }}
              >
                {t("CAC40 Index")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/nikkei");
                }}
              >
                {t("Nikkei Index")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/investment/nasdaq");
                }}
              >
                {t("Nasdaq Index")}
              </MenuItem>
            </SubMenu>
            <MenuItem
              icon={
                <Img
                  src={`${process.env.REACT_APP_URL}images/img_home.svg`}
                  alt="Home Icon"
                  className="h-[1.50rem] w-[1.38rem] pt-10"
                />
              }
              onClick={() => {
                navigate("/dashboard/withdrawal");
              }}
            >
              {t("member.menu.Withdrawal")}
            </MenuItem>
        </Menu>

      </div>


      </div>
    </>
  );
}

const themes = {
  dark: {
    sidebar: {
      backgroundColor: "#202123",
      color: "white",
    },
    menu: {
      menuContent: "#202123",
      icon: "#fffff",
      hover: {
        backgroundColor: "#343541",
        color: "#fffff",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};


// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};