import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-[20px]",
};
const variants = {
  fill: {
    dark_blue_0: "bg-dark_blue-0 text-dark_blue-1",
    primary: "bg-primary text-dark_blue-1",
  },
};
const sizes = {
  xs: "h-[1.50rem] text-[1.00rem]",
  lg: "h-[3.00rem] px-[1.25rem]",
  sm: "h-[1.88rem] pl-[0.63rem] pr-[2.13rem] text-[1.00rem]",
  md: "h-[2.50rem] pl-[1.00rem] pr-[2.13rem] text-[1.00rem]",
};

const SelectBox = React.forwardRef(
  (
    {
      children,
      className = "",
      options = [],
      isSearchable = false,
      isMulti = false,
      indicator,
      shape,
      variant = "fill",
      size = "md",
      color = "primary",
      onChange,
      height = "auto", 
      width = "auto",
      borderRadius = "0",
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={`${className} flex ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          styles={{
            indicatorsContainer: (provided) => ({
              ...provided,
              padding: undefined,
              flexShrink: undefined,
              width: "max-content",
              "& > div": { padding: 10 },
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 0,
              alignItems: "center",
              borderRadius: borderRadius,
              height: height,
              width: width,
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "none !important",
              minHeight: "auto",
              width: "100%",
              flexWrap: undefined,
              "&:hover": {
                border: "0 !important",
              }
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
            }),
            option: (provided, state) => ({
              ...provided,
              color: "#000",
            }),
            singleValue: (provided) => ({
              ...provided,
              marginLeft: undefined,
              marginRight: undefined,
              color: "white"
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: 10,
              display: "flex",
              flexWrap: undefined,
              color: "white" ,

            }),
            placeholder: (provided) => ({
              ...provided,
              margin: 0,
              color: "white"
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            menu: ({ width, ...css }) => ({ ...css }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event) => {
            return event.target.id === "scrollContainer";
          }}
          onChange={onChange}
          {...restProps}
        />
        {children}
      </>
    );
  },
);

SelectBox.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  indicator: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["xs", "lg", "sm", "md"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["dark_blue_0", "primary"]),
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string
};

export { SelectBox };
