import React from "react";
import CountUp from 'react-countup';
export default function CounterUp({ number, variation, message }) {
  return (
    <div className='h-36 w-full flex justify-center items-center flex-col'>
      <h1 className='text-7xl text-center text-green-500'>
        <CountUp duration={8} end={number} enableScrollSpy={true} />
        {variation}
      </h1>
      <h3 className='text-lg text-center text-white w-full lg:w-3/5'>
        {message}
      </h3>
    </div>
  );
}