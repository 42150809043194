import React, { useEffect } from "react";

export default function TopStoriesSection({
  containerRef,
  className=""
}) {
  
  useEffect(() => {
    const currentContainer = containerRef.current;

    // Check if the container already has a script element and remove it
    const existingScript = currentContainer.querySelector('script');
    if (existingScript) {
      currentContainer.removeChild(existingScript);
    }

    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      feedMode: "market",
      market: "crypto",
      isTransparent: true,
      displayMode: "regular",
      width: "100%",
      height: "400",
      colorTheme: "light",
      locale: "en"   
    });

    if (currentContainer) {
      currentContainer.appendChild(script);
    }

    return () => {
      if (currentContainer && currentContainer.contains(script)) {
        currentContainer.removeChild(script);
      }
    };
  }, [containerRef]);
  


  return (
    <>
      {/* top stories section */}
      {/* <div className="flex"> */}
        <div className={` ${className} flex flex-col items-start gap-[0.00rem] rounded-[20px] bg-sky_blue_2 px-[0.00rem] shadow-xs `} >
          <div ref={containerRef} className="w-full" ></div>
        </div>
      {/* </div> */}
    </>
  );
}
