import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { SnackbarProvider } from "notistack";
import "easymde/dist/easymde.min.css";
import "./styles/tailwind.css";
import "./styles/index.css";
import "./styles/font.css";
import "./styles/hamburgerMenu.css";
import "./i18n";

const title = process.env.REACT_APP_TITLE || "MIDA-MYS";
document.title = document.title.replace('%REACT_APP_TITLE%', title);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </BrowserRouter>
);
