import { Helmet } from "react-helmet";
import { Button } from "../../components/theme2";
import Header from "../../components/theme2/Header";
import Sidebar1 from "../../components/theme2/Sidebar1";
import TopStoriesSection from "./TopStoriesSection";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import usePersonalInformation from "../../hooks/zustand/usePersonalInformation";
import PerformanceSection from "./PerformanceSection";
import TechnicalAnalysisWidget from "./TechnicalAnalysisWidget";
import TodayTradeSection from "./TodayTradeSection";
import { UserProfileWidget } from "./UserProfileWidget";

export default function Dashboard() {
  const { t } = useTranslation();
  const userInfo = usePersonalInformation();

  const topStoriesRef = useRef(null);
  const technicalAnalysisRef = useRef(null);

  useEffect(() => {
    if(userInfo?.user?._id === ''){
      userInfo.getData();
    }
  }, [userInfo])

  return (
    <>
      <Helmet>
        <title>User Dashboard - Track Your Cryptocurrency Performance</title>
        <meta
          name="description"
          content="Access your investment dashboard to monitor cryptocurrency performance, view your profit, and manage your investment wallet. Stay on top of your finances with real-time updates and comprehensive trade insights."
        />
      </Helmet>
      <div className="w-full bg-sky_blue">
        <div className="mr-[1.38rem] flex items-start gap-[0.88rem] md:mr-0">
          <Sidebar1 className="inline sm:hidden"/>
          <div className="flex flex-1 flex-col gap-[2.75rem] sm:gap-0 self-center">
            <Header title={t("home.header.dashboard")} />
              <div className="flex flex-col gap-[1.25rem] sm:pb-5">
                <div className="flex gap-[1.38rem] sm:justify-center">
                  <PerformanceSection />
                  <UserProfileWidget className="sm:hidden" />
                </div>
                <div className="flex flex-row w-full justify-center items-start gap-[1.38rem] md:flex-col">
                  <TodayTradeSection className="sm:w-[80%]" />
                  <TechnicalAnalysisWidget containerRef={technicalAnalysisRef} className="sm:h-[25rem] sm:hidden" />
                </div>
                {/* top stories section */}
                <div className="w-full flex flex-row justify-center ">
                <TopStoriesSection 
                    containerRef={topStoriesRef}
                    className="w-[70%] sm:w-[80%]"
                  />
                </div>
                 
              </div>
            <Button color="dark_blue_0" size="2xl" className="self-stretch rounded-tl-[28px] rounded-tr-[28px]">
              Copyright @ { new Date().getFullYear() } 
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
