import React from "react";
import { Helmet } from "react-helmet";
import TopHeaderMenu from "../TopHeaderMenu";
import Carousel from "../Carousel";
import Footer from "components/theme2/Footer";

export default function LayoutIndex({ activeTab,  children }) {
  return (
    <>
      <Helmet>
        <title>Trade Crypto with Malaysia Ringgit - Invest Effortlessly</title>
        <meta
          name="description"
          content="Join over 10 million users trading crypto with Malaysia Ringgit. Enjoy 24/7 support, top-tier security, and a regulated platform trusted worldwide. Start investing with ease and build your foundation in the world's most popular cryptocurrencies."
        />
      </Helmet>
      <div className="w-full bg-dark_blue-1">
        <div className=" flex flex-col items-center">
          <div className="self-stretch">
            <div className="flex flex-col items-center">
              <div className="h-[70.13rem] self-stretch bg-dark_blue-0 bg-cover bg-no-repeat lg:h-auto md:h-auto">
                <div className=" bg-[url(/public/images/img_group_11.png)] bg-cover bg-no-repeat  lg:h-auto md:h-auto">
                  <div className=" flex flex-col items-center">
                    <TopHeaderMenu activeTab={activeTab} />
                    <Carousel />
                  </div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
        <div className="mt-[5.75rem]">
        <Footer />
        </div>
      </div>
      
    </>
  );
}
