import { SelectBox, Img, Text, Heading, Spacing, Input, Button } from "./..";
import React, { useState, useEffect } from "react";
import { convertTime } from "lib/table-date.formatter";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { mockLanguages } from "../../../mock/mockData";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import useAuth from "hooks/zustand/useAuth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ModalMyProfit from "../modal/modal.myprofit";
import toRinggit from "lib/currencyFormatter";
import SidebarMobile from "../SidebarMobile";

export default function Header({ title,  ...props }) {
  const { t } = useTranslation();
  const [currentDateTime, setCurrentDateTime] =  useState(new Date());
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);
  const [ddlLanguages, setDdlLanguage] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userInfo = usePersonalInformation();
  const auth = useAuth();
  const navigate = useNavigate();

  async function getInitialData(){
    try{
        await userInfo.getData();
    }
    catch(err){
        console.log(err);
    }
    
  }

  useEffect(() => {

    i18next.changeLanguage ( selectedLanguage );
    // Update the currentDateTime every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    let languageOptions = [];
    mockLanguages.forEach((key) => {
      languageOptions.push({label: key.toUpperCase(), value: key});
    });
    setDdlLanguage(languageOptions);

    console.log("Language", ddlLanguages);
    
    console.log("UserInfo", userInfo?.user);
    if (userInfo?.user._id === ''){
      getInitialData();
    }
    console.log("UserInfo", userInfo?.user);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    

  }, [userInfo?.user]); // Empty dependency array ensures the effect runs only once

  const handleLogout = () => {
    userInfo.reset();
    auth.logout();
    navigate(`/sign-in`);
  };

  const handleEmail = () => {
    navigate("/dashboard/email");
  };

  const handleChangePassword = () => {
    navigate("/dashboard/change-password");
  };

  const handleBankInformation = () => {
    navigate("/dashboard/bank-information");
  };

  return (
    <>
    <header {...props} className={`${props.className} flex sm:flex-col justify-between items-center gap-[1.25rem]`}>
      <div className="flex items-start justify-center gap-[1.00rem] self-end sm:self-auto sm:hidden">
        <a href="#">
          <Img src={`${process.env.REACT_APP_URL}images/img_menu_open.svg`} alt="Menu Icon" className="mt-[0.88rem] h-[1.75rem]" />
        </a>
        <Heading size="header" as="h1" className="self-center !text-black-900">
          {title}
        </Heading>
      </div>
      <div className="flex w-[30%] sm:w-full items-center justify-between rounded-bl-[30px] rounded-br-[30px] bg-dark_blue-0 px-[1.25rem] py-[1.00rem]">
        <Text as="p" className="ml-[0.63rem] !text-dark_blue-1 md:ml-0 sm:hidden">
          GMT+8: {convertTime(currentDateTime)}
        </Text>
        <Link to="/dashboard" >
        <Img
          src={process.env.REACT_APP_LOGO_DASHBOARD_PATH}
          alt="Sidebar Logo"
          className="h-[2.63rem] w-[11.25rem] object-contain hidden sm:inline sm:w-[70%]"
         />
         </Link>
          <a href="#" onClick={()=>{setIsSidebarMenuOpen(!isSidebarMenuOpen)}} >
          <Img src={`${process.env.REACT_APP_URL}images/img_menu_open_white.svg`} alt="Menu Icon" 
              className="mt-[0rem] h-[3.00rem]  ml-15 lg:hidden sm:block" />
         </a>
        {/* <SelectBox
          shape="round"
          indicator={
            <Img
              src={`${process.env.REACT_APP_URL}images/img_arrowdown.svg`}
              alt="Arrow Down"
              className="h-[0.06rem] h-[1.50rem] w-[0.06rem] w-[1.50rem]"
            />
          }
          name="Language Dropdown"
          placeholder={`EN`}
          options={ddlLanguages}
          onChange={(e) => {
            setSelectedLanguage(e.value);
            i18next.changeLanguage(e.value);
          }}
          value={i18next.language}
          className="w-[32%] sm:!w-[25%] font-bold"
        /> */}
      </div>
      <div className="mt-[0.75rem] mb-[0.75rem] flex-row w-[80%] rounded-full items-center justify-between gap-[1.25rem] bg-blue-200 p-5 hidden sm:flex ">
      <Img
             src={`${process.env.REACT_APP_URL}images/img_mobile_profile_icon.svg`}
             alt="Lock Icon"
             className="h-[2rem] w-[2rem]"
             onClick={()=>{setIsModalOpen(true)}}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_mobile_email_icon.svg`}
             alt="Lock Icon"
             className="h-[2rem] w-[2rem]"
             onClick={handleEmail}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_mobile_password_icon.svg`}
             alt="Password Icon"
             className="h-[2rem] w-[2rem] self-end"
             onClick={handleChangePassword}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_mobile_bank.svg`}
             alt="Balance Icon"
             className="h-[2rem] w-[2rem]"
             onClick={handleBankInformation}
           />
           <Img
             src={`${process.env.REACT_APP_URL}images/img_mobile_logout_icon.svg`}
             alt="Logout Icon"
             className="h-[2rem] w-[2rem]"
             onClick={handleLogout}
           />
         </div>
    </header>
     {/* Mobile responsive  */}
     <div className="w-full justify-start hidden sm:flex sm:pt-10 ">
      <Heading size="header" as="h1" className="self-center !text-black-900 sm:text-2xl sm:ml-10 sm:mb-[0.5rem]" >
         {title}
       </Heading>
     </div>
     <SidebarMobile isOpen={isSidebarMenuOpen} setOpen={setIsSidebarMenuOpen}  />
     <ModalMyProfit open={isModalOpen} setOpen={()=>{setIsModalOpen(false)}} title= { t("member.dashboard.My Profit") }
        titleClassName="!text-[2rem] !font-thin"
        >
          <div className="flex flex-col pt-3">
            <Heading as="p" size="heading2xl" className="!text-dark_blue-0 flex-wrap">
              {userInfo?.user?.status !== "missinginfo"
              ? userInfo?.user?.fullName || null
              : "N/A"}</Heading>
            <Spacing />
            <div className="pb-5">
            <Text className="text-base font-semibold" >{ t('member.dashboard.Investment Wallet') }</Text>
            <Input
              type="text"
              value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.wallet) : ''}`}
              shape="round"
              className="border-0"
            />
            </div>
            <div className="pb-5">
            <Text className="text-base font-semibold" > {t('member.dashboard.Deposit') }</Text>
            <Input
              type="text"
              value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.deposit) : ''}`} 
              shape="round"
              className="border-0"
            />
            </div>
            <div className="pb-5">
            <Text className="text-base font-semibold" >{ t('member.dashboard.Profit/(Loss)') }</Text>
            <Input
              type="text"
              value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.profitLoss) : ''}`}
              shape="round"
              className="border-0"
            />
            </div>
            <div className="pb-5">
            <Text className="text-base font-semibold" >{ t('member.dashboard.Total Withdrawal') }</Text>
            <Input
              type="text"
              value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.withdrawal) : ''}`}
              shape="round"
              className="border-0"
            />
            </div>
            <div className="pb-5">
            <Text className="text-base font-semibold" >{ t('member.dashboard.Total Withdrawal') }</Text>
            <Input
              type="text"
              value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.withdrawal) : ''}`}
              shape="round"
              className="border-0"
            />
            </div>
            <div className="mb-[0.50rem] flex gap-[0.88rem]">
          <Button shape="round" className="w-full font-inter font-medium">
            { t('member.dashboard.Deposit') }
          </Button>
          <Button color="black_900" shape="round" className="w-full font-inter font-medium">
             { t('member.dashboard.Withdrawal') }
          </Button>
        </div>
          </div>
        
     </ModalMyProfit>
     </>
  );
}
