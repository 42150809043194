import React, { useMemo, useEffect } from "react";
import LayoutIndex from "../Layout";
import { useTranslation } from "react-i18next";
import usePageContent from "hooks/zustand/usePageContent";
import { returnPolicy } from "lib/api/cms-route.constant";
import { shallow } from "zustand/shallow";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

export default function ReturnPolicy(){
  const {t, i18n} = useTranslation();
  const pageContent = usePageContent(
    (state) => ({ content: state.content, getData: state.getData }),
    shallow
  );

  const returnPolicyContent = useMemo(() => {
    return pageContent?.content[returnPolicy]?.content;
  }, [pageContent]);

  useEffect(() => {
    async function getData() {
      await pageContent.getData(returnPolicy, i18n.language);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  return (
    <LayoutIndex activeTab="return-policy">
         <div
        className='w-full h-full py-16'
        style={{ minHeight: 'calc(100vh - 15rem)' }}
      >
        <div className='relative w-full h-full z-10 max-w-5xl px-5 mx-auto'>
          <div className='grid grid-cols-1 gap-5'>
            <h3 className='text-black font-bold text-xl lg:text-3xl text-center'>
              {t('home.footer.ReturnPolicy')}
            </h3>
            <ReactMarkdown
              children={returnPolicyContent ?? 'Loading data...'}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              className='prose prose-sm max-w-none prose-a:text-blue-500 prose-a:underline prose-li:marker:text-black'
            />
          </div>
        </div>
      </div>
    </LayoutIndex>
  );
}