import React, {useMemo, useEffect} from "react";
import LayoutIndex from "../Layout";
import { Heading, Text } from "components/theme2";
import { useTranslation } from "react-i18next";
import usePageContent from "hooks/zustand/usePageContent";
import { shallow } from "zustand/shallow";
import { aboutUs } from "lib/api/cms-route.constant";
import CounterUp from "components/theme2/CounterUp";

export default function AboutUs(){
  const { t , i18n} = useTranslation();
  const pageContent = usePageContent(
    (state) => ({ content: state.content, getData: state.getData }),
    shallow
  );

  const about = useMemo(() => {
    return pageContent.content[aboutUs];
  }, [pageContent]);

  useEffect(() => {
    async function getData() {
      await pageContent.getData(aboutUs, i18n.language);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  return (
    <LayoutIndex activeTab='about-us'>
      <div className="px-20">
        <Heading
        as="p"
        size="heading2xl"
        className="flex w-full flex-row !text-dark_blue-0 !flex-row-wrap text-center py-10"
        >
          {about?.largeSentence}
        </Heading>
        <Heading 
          as="p"
          size="headinglg"
          className="flex w-full flex-row !text-black-900  text-center mb-3"

        >
          {about?.smallSentence}
        </Heading>
        <div className="flex flex-col w-[50%] py-10 text-dark_blue-0">
          <Heading 
            as="h1"
            className="!text-dark_blue-0"
            size="headinglg"
          >
            Our Mission
          </Heading>
          <Heading
            size="headings"
            as="h3"
            className="!text-dark_blue-0"
          >
            {about?.missionSubHeading}
          </Heading>
          <Text
            size="textmd"
            className="!text-dark_blue-0"
          >
            {about?.missionSubtitle}
          </Text>
           
        </div>

      </div>
      <div className="bg-dark_blue-0 w-full h-[400px]">
        <div className="flex flex-row flex-1 px-20 py-20">
          <div className="flex flex-col w-[50%]">
            <Heading
              size="headinglg"
            >
                    {about?.statisticTitle}
            </Heading>
            <Text size="placeholder" className=' !text-white w-full'>
                    {about?.statisticSubtitle}
            </Text>
          </div>
          <div className="flex flex-row  items-center justify-center w-[50%]">
            <CounterUp
                number={about?.numberAccountOpened ?? 100}
                variation={about?.numberVariation}
                message={about?.numberSubtitle}
              />
               <CounterUp 
                number={about?.number1 ?? 100}
                variation={about?.number1Variation?? ''}
                message={about?.number1Subtitle}
              />
              <CounterUp
                number={about?.numberCountryCovered ?? 100}
                variation={about?.numberCountryVariation}
                message={about?.numberCountrySubtitle}
              />
          </div>
         
        </div>
       
      </div>
    </LayoutIndex>
    
  );
}