import { Heading } from "../../components/theme2";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mockTestimoni } from "mock/mockData";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import ArrowDown from "components/theme1/ArrowDown";

const autoplayOptions = {
  stopOnInteraction: false,
  // stopOnMouseEnter: true,
  // playOnInit: false,
  delay: 10000, // 3 second
};

export default function TestimonialSection() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);
  const { t } = useTranslation();
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "center",
      containScroll: "trimSnaps",
      loop: true,
    },
    [Autoplay(autoplayOptions)]
  );

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaApi.plugins().autoplay?.reset();
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <>
      {/* testimonial section */}
      <div>
        <div className="flex h-[2.50rem] flex-col items-center gap-[0.19rem] bg-[url(/public/images/img_frame_11_640x1916.png)] bg-cover bg-no-repeat py-[0.19rem] lg:h-[500px] md:h-auto md:py-[0.06rem] sm:gap-[0.06rem]">
          <div className="container-xs flex flex-col items-center px-[0.19rem] lg:p-[0.06rem] md:p-[0.06rem] md:px-[0.06rem]">
            <Heading size="headings" as="h3" className=" p-10 !font-bold !text-black-900 lg:text-[0.06rem] md:text-[0.06rem]">
              { t("home.section4.testimonial.title")}
            </Heading>
            <Heading
              size="headinglg"
              as="h1"
              className="!text-primary lg:text-[0.13rem] md:text-[0.13rem] sm:text-[0.06rem]"
            >
              { t("home.section4.testimonial.subtitle")}
            </Heading>
          </div>
          <div className="container-xs">
          <div ref={emblaRef} className="overflow-hidden lg:mx-10">
            <div className="flex">
              {mockTestimoni.map(({ name, testimoni, desc }, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-center sm:flex-col flex-shrink-0 sm:w-full p-5 lg:w-1/3 h-80 lg:px-4 "
                  >
                    <div className="flex h-[12.00rem] w-[70%] items-center justify-center flex-wrap rounded-lg  flex-col gap-4 cursor-pointer  bg-white">
                      <h1 className="text-md lg:text-lg text-center italic">
                        {t(`home.section4.testimonials.${i + 1}.body`)}
                      </h1>
                      <div>
                        <p className="text-sm lg:text-md text-center font-bold">
                          {t(`home.section4.testimonials.${i + 1}.name`)}
                        </p>
                        <p className="text-sm lg:text-md text-center">
                          {t(`home.section4.testimonials.${i + 1}.title`)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="absolute top-1/2 w-full flex justify-center gap-4">
            <div className="w-full flex justify-between lg:mx-3">
              <button onClick={() => emblaApi?.scrollPrev()}>
                <span className="sr-only">Previous</span>
                <ArrowDown className="w-4 rotate-90 text-gray-400" />
              </button>
              <button onClick={() => emblaApi?.scrollNext()}>
                <span className="sr-only">Next</span>
                <ArrowDown className="w-4 -rotate-90 text-gray-400" />
              </button>
            </div>
          </div>
          <div className="absolute bottom-5 w-full flex justify-center gap-4">
            {mockTestimoni.map((_, index) => {
              return (
                <button
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === selectedIndex ? "bg-black" : "bg-gray-300"
                  }`}
                  onClick={() => {
                    emblaApi?.scrollTo(index);
                    emblaApi?.plugins().autoplay?.reset();
                  }}
                >
                  <span className="sr-only">{`Select slide ${index + 1}`}</span>
                </button>
              );
            })}
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
