import { Heading, Text } from "../../components/theme2";
import HardworkWidget from "components/theme2/HardworkWidget";
import React, { Suspense } from "react";
import { useTranslation  } from "react-i18next";

const financialStatisticsList = [
  { userImage: `${process.env.REACT_APP_URL}images/img_output.svg`, price: "$1,100,000", withdrawnEachMonth: "Withdrawneachmonth" },
  { userImage: `${process.env.REACT_APP_URL}images/img_attach_money.svg`, price: "$30,000,000", withdrawnEachMonth: "Monthlyinvestingvolume" },
  {
    userImage: `${process.env.REACT_APP_URL}images/img_groups_3.svg`,
    price: "15,000+",
    withdrawnEachMonth: "Activeinvestorsdaily",
  },
];

export default function HardworkPreparationSection() {
  const { t } = useTranslation();
 
  return (
    <>
      {/* main frame section */}
      <div className="py-[6.00rem]">
        <div className="h-[3.94rem] bg-black-900 bg-[url(/public/images/img_group_44.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
          <div className="flex flex-col items-center justify-center gap-[0.44rem] bg-indigo-900_ce py-[0.31rem] lg:gap-[0.31rem] lg:py-10 md:gap-[0.31rem] md:py-[0.06rem]  ">
            <div className="container-xs flex flex-col items-center justify-center text-center gap-[0.25rem] lg:gap-[0.25rem] lg:p-[0.06rem] md:gap-[0.19rem] md:p-[0.06rem] sm:gap-[0.13rem]">
              <div className="flex flex-col items-center gap-[0.00rem]">
                <Heading size="headings" as="h5" className="!font-bold lg:text-[0.06rem] md:text-[0.06rem] lg:flex sm:hidden">
                  { t("home.section3.hardwork preparation") }
                </Heading>
                <Text className="font-thin text-[1.8rem] !text-white py-3 lg:hidden sm:flex" >{ t("home.section3.hardwork preparation") }</Text>
                <Heading size="heading2xl" as="h6" className="lg:text-[0.13rem] md:text-[0.13rem] pb-5">
                  { t("home.section3.title")}
                </Heading>
                <Text
              className= "flex-col px-4 mb-[0.06rem] text-center !font-inter leading-[150%] lg:hidden sm:flex"
            >
              <span className="font-opensanshebrew text-2xl font-normal pb-4  text-dark_blue-1">
                <>
                  {t("home.section3.subtitle")}
                  <br />
                </>
              </span>
              <span className="font-opensanshebrew font-normal italic pb-4 text-dark_blue-1">
                {t ("home.section3.endsection") }
              </span>
            </Text>
              </div>
              <div className="w-full flex  justify-center ">
                <div className="flex flex-row w-[90%] sm:w-[70%] justify-center gap-[2.38rem] self-stretch md:flex-col sm:pb-[2rem]">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {financialStatisticsList.map((d, index) => (
                      <HardworkWidget
                        userImage={d.userImage}
                        price={d.price}
                        withdrawnEachMonth={t(`home.section3.${d.withdrawnEachMonth}`)}
                        key={"priceList" + index}
                        className="lg:gap-[0.00rem] md:w-full md:gap-[0.00rem] md:py-[0.06rem] sm:p-5"
                      />
                    ))}
                  </Suspense>
                </div>
              </div>
            
            </div>
            <Heading
              size="textlg"
              as="p"
              className="mb-[0.06rem] text-center !font-inter leading-[150%] lg:text-[0.06rem] md:text-[0.06rem] sm:hidden"
            >
              <span className="font-opensanshebrew font-bold text-dark_blue-1">
                <>
                  {t("home.section3.subtitle")}
                  <br />
                </>
              </span>
              <span className="font-opensanshebrew font-normal italic text-dark_blue-1">
                {t ("home.section3.endsection") }
              </span>
            </Heading>
          </div>
        </div>
      </div>
    </>
  );
}
