import React, {useEffect, useState} from "react";
import LayoutIndex from "../Layout";
import { Heading } from "components/theme2";
import { useTranslation } from "react-i18next";
import useNewsContent from "../../../hooks/zustand/useNewsContent";

export default function News () {
  const { t, i18n } = useTranslation();
  const useNewsStore = useNewsContent();
  const [newsList, getNewsList] = useState(null);
  const getNews = async () => {
    try {
      const newsData = await useNewsStore.getNews(i18n.language);
      const newsState = newsData;
      getNewsList(newsState);
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getNews();
  }, [i18n.language]);
  return (
    <LayoutIndex activeTab="news">
      <div className="flex flex-auto flex-col w-full ">
      <Heading
        as="p"
        size="heading2xl"
        className="flex !justify-center !text-dark_blue-0 !flex-row-wrap !text-center py-10"
        >
          {t('home.footer.News')}
        </Heading>
        <div className="flex flex-row  justify-center items-center pb-10">
        <div className="flex flex-col w-[80%]  ">
              {newsList && newsList.items.length > 0 && newsList.items.map((item) => {
                return (
                  <>
                    <a href={newsList.baseUrl + item.storyPath} target="_blank" >
                      <div className="flex flex-col  border rounded-3xl p-5 cursor-pointer bg-gray-50">
                        <div className="py-3 font-semibold text-xl ">
                          {item.title}
                        </div>
                        <div className="text-xs" >
                          Published Date : {new Date(item.published * 1000).toLocaleString('en-US', { timeZone: 'UTC' })}
                        </div>
                        <div className="text-xs" >
                          Provider : {item.provider}
                        </div>
                      </div>
                      <div className="h-5"></div>
                    </a>
                  </>
                );
              })}
        </div>
        </div>
      </div>
    </LayoutIndex>
  );

}