import React, { useState } from "react";
import "../../../styles/hamburgerMenu.css"

export default function HamburgerMenu(){
  const [isOpen, setIsOpen] = useState(false);  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }
  return (
    <div className="hamburger-menu flex items-center justify-start pr-5 z-[2]">
      <div className={`menu-icon ${isOpen ? 'open' : ''} `} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`nav-menu ${isOpen? 'open' : ''} lg:invisible sm:visible !z-[999]`}>
        <ul>
          <li><a href="/about-us">About Us</a></li>
          <li><a href="/news">News</a></li>
          <li><a href="/risk-disclosure">Risk Disclosure</a></li>
          <li><a href="/return-policy">Return Policy</a></li>
          <li><a href="/aml-policy">AML Policy</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
        </ul>
      </div>
    </div>
  );
}