import React from "react";
import { Route } from "react-router-dom";
import Routes from "components/theme2/TopLoadingBar";
import Home from "./pages/Home";
import SignIn from "pages/Authenticate/SignIn";
import NotAuthGuard from "middleware/not.auth.guard";
import ForgotPassword from "pages/Authenticate/ForgotPassword";
import SignUp from "pages/Authenticate/SignUp";
import userRoutes from "routes/user.route";
import AboutUs from "pages/Home/pages/AboutUs";
import News from "pages/Home/pages/News";
import RiskDisclosure from "pages/Home/pages/RiskDisclosure";
import ReturnPolicy  from "pages/Home/pages/ReturnPolicy";
import AmlPolicy from "pages/Home/pages/AmlPolicy";
import PrivacyPolicy from "pages/Home/pages/PolicyPrivacy";
import PageNotFound from "pages/PageNotFound";
import Awards from "pages/Home/pages/Awards";

function App() {
  return (
      <Routes>
         <Route exact path="/" element={<Home />} />
         <Route exact path="/sign-in" element={
          <NotAuthGuard >
            <SignIn />
          </NotAuthGuard>
         } />
         <Route path=":language/forgot-password" element={ <ForgotPassword />} />
         <Route path="/sign-up" element={ <SignUp  /> } />
         <Route path="/about-us" element={<AboutUs />} />
         <Route path="/news" element={<News />}  />
         <Route path="/risk-disclosure" element={<RiskDisclosure />} />
         <Route path="/return-policy" element={<ReturnPolicy />} />
         <Route path="/aml-policy" element={<AmlPolicy />} />
         <Route path="/privacy-policy" element={<PrivacyPolicy />} />
         <Route path="/awards" element={<Awards />} />
         {userRoutes}

         <Route path="*" element={< PageNotFound /> } />
      </Routes>
  );
}

export default App;
