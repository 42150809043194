import { Text, Heading, Img } from "./..";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer({ ...props }) {
  const { t } = useTranslation();
  return (
    <footer
      {...props}
      className={`${props.className} flex items-end sm:flex-col lg:h-auto md:h-auto  pl-[9.88rem] pr-[3.50rem] py-[4.75rem] lg:pl-[2.00rem] lg:py-[2.00rem] md:p-[1.25rem] sm:p-[1.00rem] relative bg-dark_blue-0 bg-[url(/public/images/img_frame_12.png)] bg-cover bg-no-repeat`}
    >
      <div className=" flex w-[44%] items-start justify-between gap-[1.25rem] lg:w-full md:w-full sm:w-full md:flex-col sm:flex-col">
        <div className="flex w-[32%] flex-col items-start self-stretch justify-center gap-[1.25rem] md:w-ful sm:w-full">
          <div className="mr-[0.38rem] flex itemgap-[0.25rem] lg:mr-0 md:mr-0">
            <Img
              src={process.env.REACT_APP_LOGO_BG_PATH}
              alt="Stars Image"
              className="w-[50%]"
            />
          </div>
          <Text size="textxs" as="p" className="visible !text-dark_blue-1  z-10 sm:invisible">
            Copyright © {new Date().getFullYear()}, All Rights Reserved
          </Text>
        </div>
        <div className="mt-[0.25rem] lg:flex w-[40%] items-start justify-between gap-[1.25rem] self-center 
            md:w-full sm:w-full sm:justify-start sm:flex-col">
          <div className="flex w-[62%] flex-col items-end sm:items-start gap-[0.13rem] lg:self-end sm:justify-start sm:text-left sm:w-full">
            <Heading
              size="headingsm"
              as="h2"
              className="!text-primary pr-5 lg:text-[1.75rem] md:text-[1.75rem] sm:text-[1.63rem]"
            >
              {t("home.footer.Sitemap")}
            </Heading>
            <ul className="flex flex-col items-start gap-[0.50rem]">
              <li>
                <a
                  href="/about-us"
                  className="lg:text-[1.44rem] md:text-[1.38rem]"
                >
                  <Text
                    size="textmd"
                    as="p"
                    className="!font-opensanscondensed !font-light !text-dark_blue-1 !text-lg"
                  >
                    {t("home.footer.AboutUs")}
                  </Text>
                </a>
              </li>
              <li>
                <a
                  href="/news"
                  target="_blank"
                  rel="noreferrer"
                  className="lg:text-[1.44rem] md:text-[1.38rem]"
                >
                  <Text
                    size="textmd"
                    as="p"
                    className="!font-opensanscondensed !font-light !text-dark_blue-1 !text-lg"
                  >
                    {t("home.footer.News")}
                  </Text>
                </a>
              </li>
              <li>
                <a
                  href="/risk-disclosure"
                  className="lg:text-[1.44rem] md:text-[1.38rem]"
                >
                  <Text
                    size="textmd"
                    as="p"
                    className="!font-opensanscondensed !font-light !text-dark_blue-1 !text-lg"
                  >
                    {t("home.footer.RiskDisclosure")}
                  </Text>
                </a>
              </li>
              <li>
                <a
                  href="return-policy"
                  className="lg:text-[1.44rem] md:text-[1.38rem]"
                >
                  <Text
                    size="textmd"
                    as="p"
                    className="!font-opensanscondensed !font-light !text-dark_blue-1 !text-lg"
                  >
                    {t("home.footer.ReturnPolicy")}
                  </Text>
                </a>
              </li>
            </ul>
          </div>
          <ul className="mt-[3.75rem] flex flex-col items-start gap-[0.13rem] sm:mt-0">
            <li>
              <a
                href="/aml-policy"
                className="lg:text-[1.44rem] md:text-[1.38rem]"
              >
                <Text
                  size="textmd"
                  as="p"
                  className="!font-opensanscondensed !font-light !text-dark_blue-1 !text-lg "
                >
                  {t("home.footer.AMLPolicy")}
                </Text>
              </a>
            </li>
            <li>
              <a
                href="/privacy-policy"
                className="lg:text-[1.44rem] md:text-[1.38rem]"
              >
                <Text
                  as="p"
                  className="!font-opensanscondensed !font-light !text-dark_blue-1 !text-lg"
                >
                  {t("home.footer.PrivacyPolicy")}
                </Text>
              </a>
            </li>
          </ul>
        </div>
        <div className="lg:invisible sm:visible">
        <Text size="textxs" as="p" className=" !text-dark_blue-1  z-10 sm:visible ">
            Copyright © {new Date().getFullYear()}, All Rights Reserved
          </Text>
          </div>
      </div>
    </footer>
  );
}
