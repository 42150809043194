import { Heading, Img } from "./..";
import React from "react";
import { useTranslation } from "react-i18next";

export default function HomeTrustedProfile({ title, index, ...props }) {
  const { t } = useTranslation();
  return (
    <div
      {...props}
      className={`${props.className} flex items-center justify-start text-center`}
    >
      <div className="flex flex-1 flex-wrap flex-col items-center gap-[0.75rem]">
        <Img
          src={`${process.env.REACT_APP_URL}images/img_handshake.svg`}
          alt="Handshake Image"
          className="ml-[8.13rem] h-[4.25rem] w-[4.25rem] sm:ml-0"
        />
        <Heading size="headinglg" as="h2" className="lg:ml-[5.38rem] sm:ml-0">
          {title}
        </Heading>
        <Heading
          size="headings"
          as="h4"
          className=" w-[80%] text-center !font-opensans leading-[150%] !text-black-900"
        >
          {t(`home.section2.${index}`)}
        </Heading>
      </div>
      <div className="h-[10.00rem] w-[0.06rem] self-end bg-dark_blue-1 sm:invisible" />
    </div>
  );
}
