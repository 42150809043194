import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-lg",
};

const variants = {
  fill: {
    dark_blue_0: "bg-dark_blue-0 shadow-xs text-dark_blue-1",
    sky_blue_2: "bg-sky_blue_2 shadow-xs text-gray-500",
    dark_blue_1: "bg-dark_blue-1 text-gray-600",
    sky_blue: "bg-sky_blue text-black-900",
  },
};

const sizes = {
  md: "h-[4.88rem] px-[2.13rem] text-[1.88rem]",
  sm: "h-[3.00rem] pl-[1.00rem] pr-[2.13rem]",
  lg: "h-[7.88rem] pl-[1.00rem] pr-[2.13rem] text-[2.50rem]",
  xs: "h-[3.00rem] pl-[1.00rem] pr-[2.13rem] text-[1.13rem]",
};

const Input = React.forwardRef(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "fill",
      size = "xs",
      color = "sky_blue",
      ...restProps
    },
    ref,
  ) => {
    return (
      <label
        className={`${className} flex items-center justify-center cursor-text  ${(shape && shapes[shape]) || ""} ${variants[variant]?.[color] || variants[variant] || ""} ${sizes[size] || ""}`}
      >
        {!!label && label}
        {!!prefix && prefix}
        <input ref={ref} type={type} name={name} placeholder={placeholder} onChange={onChange} {...restProps} className={className} />
        {!!suffix && suffix}
      </label>
    );
  },
);
Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["md", "sm", "lg", "xs"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["dark_blue_0", "sky_blue_2", "dark_blue_1", "sky_blue"]),
};

export { Input };
