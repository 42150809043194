import React, { useRef, useState } from "react";
import { Button, Heading } from "../../components/theme2";
import ChartWidget from "./ChartWidget";
import { useTranslation  } from "react-i18next";

export default function PerformanceSection() {

  const { t } = useTranslation();

  const cryptoRef = useRef(null);
  const shareIndexRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('crypto');

  const btnCryptoClick = () => {
    setSelectedTab('crypto');
  }

  const btnShareIdxClick = () => {
    setSelectedTab('shareIndex');
  }

  return (
    <div className="w-[100%] sm:w-[80%] flex  flex-col  ">
    {selectedTab === 'crypto' && (
      <div className="flex flex-1 flex-col gap-[1.63rem] rounded-[20px] bg-dark_blue-0 px-[0.50rem] py-[1.25rem] shadow-xs md:self-stretch md:p-[1.25rem]">
        <div className="flex items-center justify-center md:flex-col">
          <Heading as="h1" className="sm:pb-5 sm:text-lg">{ t("member.dashboard.Cryptocurrency Performance") }</Heading>
          <div className="flex flex-1 justify-end gap-[0.56rem] md:self-stretch">
            <Button
              color="black_900"
              size="md"
              className="min-w-[9.25rem] sm:min-w-[4.25rem] rounded-[20px]"
              onClick = {btnCryptoClick}
            >
              { t("member.dashboard.Crypto") }
            </Button>
            <Button
              color="gray_100"
              size="md"
              className="min-w-[12.25rem] sm:min-w-[7.25rem] rounded-[20px]"
              onClick = {btnShareIdxClick}
            >
              { t("member.dashboard.Share Index") }
            </Button>
          </div>
        </div>
        <ChartWidget containerRef={cryptoRef} chartType="crypto" />
      </div>
    )}
    {selectedTab === 'shareIndex' && (
      <div className="flex flex-1 w-full flex-col gap-[1.63rem] rounded-[20px] bg-dark_blue-0 px-[0.50rem] py-[1.25rem] shadow-xs md:self-stretch md:p-[1.25rem]">
        <div className="flex items-center justify-center md:flex-col">
          <Heading as="h1"> { t("member.dashboard.Share Index Performance") } </Heading>
          <div className="flex flex-1 justify-end gap-[0.56rem] md:self-stretch">
            <Button
              color="gray_100"
              size="md"
              className="min-w-[9.25rem] rounded-[20px]"
              onClick = {btnCryptoClick}
            >
              Crypto
            </Button>
            <Button
              color="black_900"
              size="md"
              className="min-w-[12.25rem] rounded-[20px]"
              onClick = {btnShareIdxClick}
            >
              Share Index
            </Button>
          </div>
        </div>
        <ChartWidget containerRef={shareIndexRef} chartType="shareIndex" />
      </div>
    )}
    </div>
  )
}
