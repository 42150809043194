import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Img,
  Text,
  Slider,
  SelectBox,
} from "../../components/theme2";
import { useTranslation } from "react-i18next";
import { mockLanguages } from "mock/mockData";
import i18next from "i18next";
import { Link } from "react-router-dom";
import HamburgerMenu from "components/theme2/HamburgerMenu";

export default function TopHeaderMenu({ activeTab }) {
  const { t } = useTranslation();
  const [languageOptions, setLanguageOptions] = useState([]);

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng, (err) => {
      console.warn("An error occured while changin language");
    });
  };

  useEffect(() => {
    let options = [];
    mockLanguages.forEach((language) => {
      options.push({ label: language.toUpperCase(), value: language });
    });
    setLanguageOptions(options);
    console.log("language", options);
  }, []);

  return (
    <>
      <div className="container-xs  flex flex-col items-center  px-[3.50rem]  md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem] sm:!gap-0 lg:p-[1.25rem] lg:gap-[1.88rem]">
        <div className="flex flex-col items-center gap-[0.50rem] sm:flex-col w-full justify-center">
          <div className="w-full flex flex-row justify-end gap-3">
            <Button
              color="blue_A700"
              size="xs"
              className="min-w-[7.25rem] rounded-[16px] font-inter font-medium"
            >
              <a href="sign-in"> {t("home.signin.login")} </a>
            </Button>
            <SelectBox
              size="md"
              shape="round"
              indicator={
                <Img
                  src={`${process.env.REACT_APP_URL}images/img_arrowdown.svg`}
                  alt="Arrow Down"
                />
              }
              height="35px"
              width="8%"
              borderRadius="20px"
              name="Language Dropdown"
              placeholder={`EN`}
              options={languageOptions}
              onChange={(e) => {
                changeLanguage(e.value);
              }}
              className="font-inter text-white sm:hidden"
            />
             <SelectBox
              size="md"
              shape="round"
              indicator={
                <Img
                  src={`${process.env.REACT_APP_URL}images/img_arrowdown.svg`}
                  alt="Arrow Down"
                />
              }
              height="35px"
              width="30%"
              borderRadius="20px"
              name="Language Dropdown"
              placeholder={`EN`}
              options={languageOptions}
              onChange={(e) => {
                changeLanguage(e.value);
              }}
              className="font-inter text-white sm:flex lg:hidden"
            />
          </div>
          <div className="flex flex-row flex-grow w-full justify-center">
            <div className="flex  sm:w-[80%]">
              <Link to={`/`}>
                <Img
                  src={process.env.REACT_APP_LOGO_BG_PATH}
                  alt="Star Image"
                  className="h-[5.25rem] sm:h-[3rem] sm:left-0 md:h-[5rem] lg:h-[5.25rem]  sm:w-[40%]"
                />
              </Link>
            </div>
            <HamburgerMenu />
          </div>
        </div>
        <div className="mr-[1.00rem] flex flex-wrap lg:gap-[2.75rem] lg:mr-0 md:mr-0 sm:invisible sm:h-0">
          <Link to="/about-us">
            <Text
              size="textsm"
              as="p"
              className={`  ${
                activeTab === "about-us" ? "!text-red-700 !underline" : ""
              }  !font-opensanshebrewcondensed !text-dark_blue-1 lg:text-[1.44rem] md:text-[1.38rem]`}
            >
              {t("home.footer.AboutUs")}
            </Text>
          </Link>
          <Link to="/news">
            <Text
              size="textsm"
              as="p"
              className={` ${
                activeTab === "news" ? "!text-red-700 !underline" : ""
              } !font-opensanshebrewcondensed !text-dark_blue-1 lg:text-[1.44rem] md:text-[1.38rem]`}
            >
              {t("home.footer.News")}
            </Text>
          </Link>
          <Link to="/risk-disclosure">
            <Text
              size="textsm"
              as="p"
              className={` ${
                activeTab === "risk-disclosure"
                  ? "!text-red-700 !underline"
                  : ""
              } !font-opensanshebrewcondensed !text-dark_blue-1 lg:text-[1.44rem] md:text-[1.38rem] `}
            >
              {t("home.footer.RiskDisclosure")}
            </Text>
          </Link>
          <Link to="/return-policy">
            <Text
              size="textsm"
              as="p"
              className={` ${
                activeTab === "return-policy" ? "!text-red-700 !underline" : ""
              }  self-end !font-opensanshebrewcondensed !text-dark_blue-1 lg:text-[1.44rem] md:text-[1.38rem]`}
            >
              {t("home.footer.ReturnPolicy")}
            </Text>
          </Link>
          <Link to="/aml-policy">
            <Text
              size="textsm"
              as="p"
              className={`  ${
                activeTab === "aml-policy" ? "!text-red-700 !underline" : ""
              }  self-end !font-opensanshebrewcondensed !text-dark_blue-1 lg:text-[1.44rem] md:text-[1.38rem]`}
            >
              {t("home.footer.AMLPolicy")}
            </Text>
          </Link>
          <Link to="/privacy-policy">
            <Text
              size="textsm"
              as="p"
              className={` ${
                activeTab === "privacy-policy" ? "!text-red-700 !underline" : ""
              } self-end !font-opensanshebrewcondensed !text-dark_blue-1 lg:text-[1.44rem] md:text-[1.38rem] `}
            >
              {t("home.footer.PrivacyPolicy")}
            </Text>
          </Link>
        </div>
      </div>
    </>
  );
}
