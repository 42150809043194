import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import usePersonalInformation from "../../hooks/zustand/usePersonalInformation";
import { Button, Heading, Input, Text } from "../../components/theme2";
import toRinggit from "lib/currencyFormatter";

export function UserProfileWidget ({ className}) {

    const { t } = useTranslation();
  const userInfo = usePersonalInformation();

    useEffect(() => {
        if(userInfo?.user?._id === ''){
          userInfo.getData();
        }
      }, [userInfo])

    return (
        <div className={` ${className} rounded-[20px] bg-sky_blue_2 p-[1.00rem] shadow-xs  md:w-full md:p-[1.25rem]`}>
        <div className="flex flex-col gap-[0.50rem]">
          <div className="flex flex-col items-start">
            <Text size="header_light" as="p" className="!text-dark_blue-0">
              { t("member.dashboard.My Profit") }
            </Text>
            <Heading size="header" as="h2" className="!text-dark_blue-0">
            {userInfo?.user?.status !== "missinginfo"
              ? userInfo?.user?.fullName || null
              : "N/A"}
            </Heading>
          </div>
          <div className="flex flex-col items-start gap-[0.25rem]">
            <Text as="p">{ t('member.dashboard.Investment Wallet') }</Text>
            <Input shape="round" name="Wallet Input" value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.wallet) : ''}`} className="self-stretch border-0 " readOnly />
          </div>
        </div>
        <div className="mt-[1.75rem] flex flex-col items-start gap-[0.25rem]">
          <Text as="p">{ t('member.dashboard.Deposit') }</Text>
          <Input shape="round" name="Deposit Input" value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.deposit) : ''}`} className="self-stretch border-0" readOnly />
        </div>
        <div className="mt-[1.75rem] flex flex-col items-start gap-[0.25rem]">
          <Text as="p">{ t('member.dashboard.Profit/(Loss)') }</Text>
          <Input
            shape="round"
            name="ProfitLoss Input"
            value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.profitLoss) : ''}`}
            className="self-stretch border-0"
          />
        </div>
        <div className="mt-[1.75rem] flex flex-col items-start gap-[0.25rem]">
          <Text as="p"> { t('member.dashboard.Total Withdrawal') } </Text>
          <Input shape="round" name="Total Input" value={`${userInfo?.user?.status !== 'missinginfo' ? toRinggit(userInfo?.user?.withdrawal) : ''}`} className="self-stretch border-0" readOnly />
        </div>
        <div className="mb-[0.50rem] mt-[2.63rem] flex gap-[0.88rem]">
          <Button shape="round" className="w-full font-inter font-medium">
            { t('member.dashboard.Deposit') }
          </Button>
          <Button color="black_900" shape="round" className="w-full font-inter font-medium">
             { t('member.dashboard.Withdrawal') }
          </Button>
        </div>
      </div> 
    );
}