import { Heading, Img, Text } from "./..";
import React from "react";

export default function HardworkWidget({
  userImage = `${process.env.REACT_APP_URL}images/img_output.svg`,
  price = "$1,100,000",
  withdrawnEachMonth = "Withdrawn each month",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center justify-center w-[28%] gap-[1.75rem] px-[1.13rem] py-[2.88rem] border-sky_blue border-[23px] border-solid rounded-[208px]`}
    >
      <Img src={userImage} alt="Product Image" className="h-[5.00rem] w-[5.00rem]" />
      <Heading size="headinglg" as="h1" className="visible sm:hidden">
        {price}
      </Heading>
      <Text className="sm:flex font-semibold text-[2rem] !text-white lg:hidden" >{price}</Text>
      <Heading size="headings" as="h3" className=" text-center !font-opensans leading-[150%]  sm:hidden">
        {withdrawnEachMonth}
      </Heading>
      <Text className="sm:flex font-semibold text-[1.5rem] !text-white lg:hidden" >{withdrawnEachMonth}</Text>
    </div>
  );
}
