import { Button, Heading, Img, Slider } from "../../components/theme2";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import ArrowDown from "components/theme1/ArrowDown";
import { Link } from "react-router-dom";
import AwardsViewWidget from "components/theme2/AwardViewWidget";

const autoplayOptions = {
  stopOnInteraction: false,
  // stopOnMouseEnter: true,
  // playOnInit: false,
  delay: 10000, // 3 second
};

export default function AwardsSection() {
  const [sliderState, setSliderState] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const sliderRef = React.useRef(null);
  const { t } = useTranslation();
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "center",
      containScroll: "trimSnaps",
      loop: true,
    },
    [Autoplay(autoplayOptions)]
  );

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    emblaApi.plugins().autoplay?.reset();
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <>
      {/* awards section */}
      <div className="mt-[5.13rem] flex flex-col items-center">
        <div className="container-xs flex flex-col items-center gap-[0.25rem] px-[0.06rem] lg:gap-[0.25rem] lg:p-[0.06rem] md:gap-[0.19rem] md:p-[0.06rem] sm:gap-[0.13rem]">
          <div className="mr-[0.06rem] self-stretch lg:mr-0 md:mr-0">
            <div className="flex flex-col items-center gap-[0.00rem]">
              <Heading
                size="headingxl"
                as="h1"
                className="!text-primary lg:text-[0.13rem] md:text-[0.13rem] sm:text-[0.06rem]"
              >
                { t("home.section5.title") }
              </Heading>
            </div>
          </div>
          <div>
          <div ref={emblaRef} className="overflow-hidden">
          <div className="flex my-10 gap-4">
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Forex Copy Trading Platform 2023.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Forex Copy Trading Platform 2023
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Trading Conditions Asia 2021.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Trading Conditions Asia 2021
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Forex Broker India 2023.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Forex Broker India 2023
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best ECN Broker 2023.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best ECN Broker 2023
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Trading Platform 2022.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Trading Platform 2022
                </div>
              </div>
            </div>
          </div>
        </div>
          </div>
          <Link to="/awards">
          <Button color="black_900" shape="round" className="min-w-[0.81rem] font-inter font-medium">
            { t ("home.section5.View all awards") }
          </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
