import React, { Suspense } from "react";
import HomeTrustedProfile from "components/theme2/HomeTrustedProfile";
import { mockTrushted } from "mock/mockData";
import { useTranslation } from "react-i18next";
const filterByLanguage = (array, language) =>{
  return array.filter(item => item[`title_${language}`]);
}

export default function TrustedSection () {
  const { i18n } = useTranslation();
  const arrMockTrushted = filterByLanguage(mockTrushted, i18n.language);

  return (
    <>
       <div className=" sm:invisible flex relative z-[2] mt-[-7.38rem] lg:p-[1.25rem] sm:p-[1.25rem] sm:h-[0px]">
                <div className=" h-[15.00rem] flex lg:gap-[5.38rem] rounded-[18px] bg-primary px-[3.50rem] 
                py-[0.63rem] sm:flex-col md:px-[1.25rem] sm:px-[1.00rem]">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {arrMockTrushted.map((d, index) => (
                      <HomeTrustedProfile
                        title={d[`title_${i18n.language}`]}
                        index={index + 1}
                        className="mb-[0.88rem] mt-[0.25rem] w-[30%] sm:my-0 sm:w-full"
                      />
                    ))}
                  </Suspense>
                </div>
      </div>
      <div className="lg:invisible lg:h-0 sm:visible relative flex flex-col z-[2] mt-[1.0rem]   "> 
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {arrMockTrushted.map((d, index) => (
                      <>
                      <div className={` h-[15.00rem] flex  ${index%2 === 0 ? 'bg-primary' : 'bg-blue-400'}  
                      py-[0.63rem] flex-col px-[1.00rem] `}>
                      <HomeTrustedProfile
                        title={d[`title_${i18n.language}`]}
                        index={index + 1}
                        className="mb-[0.88rem] mt-[0.25rem] w-[30%] sm:my-0 sm:w-full"
                      />
                      </div>
                      </>
                    ))}
                  </Suspense>
                </div>
    </>
  )
}