import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { convertTime } from "lib/table-date.formatter";
import { Text }from "../."
import { mockLanguages } from 'mock/mockData';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function ModalMyProfit({ open, setOpen, children, title, titleClassName }) {
  const [currentDateTime, setCurrentDateTime] =  useState(new Date());
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { t } = useTranslation();
  useEffect(()=>{
    i18next.changeLanguage ( selectedLanguage );
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  },[] )
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-blue-100 bg-opacity-50 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-5'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg  text-left shadow-xl transition-all sm:my-8 max-w-full w-full lg:max-w-2xl'>
               <div className='bg-dark_blue-0'>
                  <div className='h-[3rem] flex items-center p-3 flex-row w-full'>
                 
                    <Text as="p" className="ml-[0.63rem] !text-dark_blue-1 md:ml-0 w-full">
                    GMT+8: {convertTime(currentDateTime)}
                    </Text>
             
                    <div className='flex w-full justify-end '>
                    { (mockLanguages.length > 1) && (
                      <select
                        defaultValue="en"
                        className="border-1 text-sm !w-[4rem] right-0 text-white"
                        onChange={(e) => {
                          setSelectedLanguage(e.target.value);
                          i18next.changeLanguage(e.target.value);
                        }}
                        value={ i18next.language }
                      >
                      {mockLanguages.map((langCode) => (
                            <option key={langCode} value={langCode}>
                                {langCode.toUpperCase()}
                            </option>
                        ))}
                      </select>
                      )}
                    </div>
                  
                  </div>
                </div>
                <div className='bg-sky_blue_2 p-5 sm:p-3 sm:pb-2 w-full'>
                 
                  <div className='sm:flex sm:items-start w-full'>
                    <div className='mt-3 w-full'>
                      <Dialog.Title as='h3' className={`font-semibold leading-6 ${titleClassName} `}>
                        {title}
                      </Dialog.Title>
                      {children}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
