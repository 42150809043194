import { Heading } from "../../components/theme2";
import SignUpForm from "../../components/theme2/SignUpForm";
import React, { Suspense } from "react";
import { useTranslation  } from "react-i18next";

const registrationProcessList = [
  {
    userImage: `${process.env.REACT_APP_URL}/images/img_image.png`,
    signUpLink: "Sign Up",
    createAccountText: "Create an account for free using your email address",
  },
  {
    userImage: `${process.env.REACT_APP_URL}images/img_image_286x404.png`,
    signUpLink: "Explore the platform",
    createAccountText: "See what it like to invest",
  },
  {
    userImage: `${process.env.REACT_APP_URL}images/img_image_1.png`,
    signUpLink: "Invest & earn profit",
    createAccountText: "Select an asset and make your money work for you",
  },
];

export default function TakePartNowSection() {
  const { t } = useTranslation();
  return (
    <>
      {/* take part now section */}
      <div>
        <div className="flex  h-[3.13rem] flex-col items-center justify-center bg-[url(/public/images/img_frame_10.png)] bg-cover bg-no-repeat py-[0.31rem] lg:h-auto lg:py-10 md:h-auto md:py-[0.06rem]">
          <div className="container-xs mb-[0.00rem] flex flex-col items-center lg:p-[0.06rem] md:p-[0.06rem]">
            <Heading size="headings" as="h3" className="!font-bold !text-black-900 lg:text-[0.06rem] md:text-[0.06rem]">
              { t("home.section6.title") }
            </Heading>
            <Heading
              size="headinglg"
              as="h1"
              className="mt-[0.00rem] mb-[1.00rem] !text-primary lg:text-[0.13rem] md:text-[0.13rem] sm:text-[0.06rem]"
            >
              { t("home.section6.subtitle") }
            </Heading>
            <div className="justify-center mr-[0.00rem] mt-[0.13rem] flex gap-[0.44rem] self-stretch lg:mr-0 md:mr-0 md:flex-col">
              <Suspense fallback={<div>Loading feed...</div>}>
                {registrationProcessList.map((d, index) => (
                  <SignUpForm 
                  userImage={d.userImage}
                  signUpLink={d.signUpLink}
                  createAccountText={t(`home.section6.${d.createAccountText}`)}
                  key={"signupList" + index} 
                  />
                ))}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
