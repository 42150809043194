import { Helmet } from "react-helmet";
import { Button, Heading,  } from "../../components/theme2";
import Footer from "../../components/theme2/Footer";
import AwardsSection from "./AwardsSection";
import TakePartNowSection from "./TakePartNowSection";
import TestimonialSection from "./TestimonialSection";
import TrustedSection from "./TrustedSection";
import InvestSection from "./InvestSection";

import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import i18n from "i18n";
import Carousel from "./Carousel";
import TopHeaderMenu from "./TopHeaderMenu";
import HardworkPreparationSection from "./HardworkPreparationSection";
import { Link } from "react-router-dom";



export default function MainframePage() {
  const [selectedLang, setSelectedLang] = useState('en');
  const { t } = useTranslation();

  const cmbLanguageClicked = (e) => {
    console.log(e.value);
    setSelectedLang(e.value);
    i18next.changeLanguage(e.value);
  }

  useEffect( () => {
    i18next.language = i18n.language;
  }, i18n.language);

  return (
    <>
      <Helmet>
        <title>Trade Crypto with Malaysia Ringgit - Invest Effortlessly</title>
        <meta
          name="description"
          content="Join over 10 million users trading crypto with Malaysia Ringgit. Enjoy 24/7 support, top-tier security, and a regulated platform trusted worldwide. Start investing with ease and build your foundation in the world's most popular cryptocurrencies."
        />
      </Helmet>
      <div className="w-full bg-dark_blue-1">
        <div className=" flex flex-col items-center">
          
          <div className="self-stretch">
            <div className="flex flex-col items-center">
              <div className="h-[70.13rem] self-stretch bg-dark_blue-0 bg-cover bg-no-repeat lg:h-auto md:h-auto">
                <div className=" bg-[url(/public/images/img_group_11.png)] bg-cover bg-no-repeat  lg:h-auto md:h-auto">
                  <div className=" flex flex-col items-center">
                    <TopHeaderMenu />
                    <Carousel />
                  </div>
                </div>
              </div>
             <TrustedSection />
            </div>

            {/* main frame section */}
            <InvestSection />

            {/* main frame section */}
            <HardworkPreparationSection />

            {/* take part now section */}
            <TakePartNowSection />

            {/* testimonial section */}
            <TestimonialSection />

            {/* awards section */}
            <AwardsSection />
            <div className="mt-[5.75rem]">
              <div>
                <div className="relative z-[3] ml-[16.00rem] mr-[12.75rem] flex h-[19.00rem] justify-center  bg-primary bg-[url(/public/images/img_frame_11.png)] bg-cover bg-no-repeat p-[1.63rem] lg:mx-0 lg:h-auto md:mx-0 md:h-auto sm:p-[1.00rem]">
                  <div className="mb-[1.25rem] flex justify-center flex-col  gap-[1.63rem]  md:w-full">
                    <Heading
                      size="headinglg"
                      as="h1"
                      className=" leading-[150%] lg:w-full lg:text-[2.63rem] md:w-full md:text-[2.13rem] sm:text-[1.75rem]"
                    >
                      { t("home.section4.end.title") }
                    </Heading>
                    <div className="flex w-full justify-center">
                      <Link to="/sign-up">
                      <Button color="black_900" shape="round" className="min-w-[13.00rem] w-[20%] flex  font-bold">
                      { t("home.section4.end.button") }
                      </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
