import React, { useEffect } from "react";

export default function ChartWidget({
  containerRef,
  chartType = 'crypto'
}) {

  useEffect(() => {
    const jsonContent = {
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "en",
      width: "100%",
      height: "500",
      largeChartUrl: "",
      isTransparent: true,
      showSymbolLogo: true,
      showFloatingTooltip: true,
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(19, 23, 34, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Crypto",
          symbols: [
            { s: "COINBASE:ETHUSD", d: "ETCUSD" },
            { s: "COINBASE:BTCUSD", d: "BTCUSD" },
            { s: "COINBASE:DOTUSD", d: "DOTUSD" },
            { s: "CRYPTO:HTUSD", d: "HTUSD" },
            { s: "COINBASE:XRPUSD", d: "XRPUSD" },
            { s: "COINBASE:DOGEUSD", d: "DOGEUSD" },
            { s: "BINANCE:BNBUSD", d: "BNBUSD" },
            { s: "BINANCE:TRXUSD", d: "TRXUSD" },
            { s: "COINBASE:SOLUSD", d: "SOLUSD" },
            { s: "COINBASE:USDTUSD", d: "USDTUSD" }
          ]
        }
      ]
    };
    const jsonContent2 = {
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "en",
      width: "100%",
      height: "500",
      largeChartUrl: "",
      isTransparent: true,
      showSymbolLogo: true,
      showFloatingTooltip: true,
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(19, 23, 34, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Share",
          symbols: [
            { s: "FOREXCOM:SPXUSD", d: "S&P 500 Index" },
            { s: "FOREXCOM:DJI", d: "Dow Jones Industrial Average Index" },
            { s: "INDEX:NKY", d: "Nikkei 225" },
            { s: "INDEX:DEU40", d: "DAX Index" },
            { s: "CAPITALCOM:DXY", d: "DXY" },
            { s: "CAPITALCOM:VIX", d: "VIX" },
            { s: "HSI:HSI", d: "HSI" },
            { s: "INDEX:CAC40", d: "CAC40" },
            { s: "NASDAQ:NDX", d: "NDX" }
          ]
        }
      ]
    };

    let isMounted = true;
    const script = document.createElement('script');
    if (chartType === 'crypto'){
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
      script.async = true;
      script.type = 'text/javascript';
      script.innerHTML = JSON.stringify(jsonContent);
    }
    else if (chartType === 'shareIndex') {
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
      script.async = true;
      script.type = 'text/javascript';
      //script.innerHtml = JSON.stringify(jsonContentShareIndex);
      script.innerHTML = JSON.stringify(jsonContent2);
    }
    
    const currentContainerRef = containerRef.current;

    if (currentContainerRef && isMounted) {
      currentContainerRef.appendChild(script);
    }

    console.log(currentContainerRef);

    return () => {
      if (currentContainerRef && currentContainerRef.contains(script)) {
        currentContainerRef.removeChild(script);
        isMounted = false;
      }
    }

  }, [containerRef, chartType])

  return (
    <div ref={containerRef}></div>
  );

}