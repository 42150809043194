import { Heading, Img } from "./..";
import React from "react";

export default function SignUpForm({
  userImage = `${process.env.REACT_APP_URL}images/img_image.png`,
  signUpLink = "Sign up",
  createAccountText = "Create an account for free using your email address",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[28%] md:w-full gap-[1.50rem] bg-dark_blue-1 shadow-xs rounded-[16px]`}
    >
      <Img
        src={userImage}
        alt="Signup Image"
        className="h-[17.88rem] w-full rounded-bl-lg rounded-br-lg rounded-tl-[16px] rounded-tr-[16px] object-cover"
      />
      <div className="flex flex-col items-start gap-[0.13rem] self-stretch px-[0.88rem] py-[0.63rem]">
        <a href="#">
          <Heading size="body_text" as="p" className="!text-black-900">
            {signUpLink}
          </Heading>
        </a>
        <Heading size="body_text" as="p" className="mb-[0.38rem] w-full leading-[150%] !text-gray-600">
          {createAccountText}
        </Heading>
      </div>
    </div>
  );
}
