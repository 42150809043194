import React, { useEffect, useState } from 'react';
import { firebaseAuth } from '../../lib/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
// components
import { FormProvider, RHFInputAddon } from '../../components/theme2/RHForm';
import ButtonLp from '../../components/theme2/ButtonLp';
import OTPInput from 'react-otp-input';
import { enqueueSnackbar } from 'notistack';
import useAuth from '../../hooks/zustand/useAuth';
import useHomeSlider from '../../hooks/zustand/useHomeSlider';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submited, setSubmit] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [country, setCountry] = useState('+60');
  const auth = useAuth();
  const navigate = useNavigate();
  const homeSliderStore = useHomeSlider();
  const [bgImg, setBgImg ] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  const [bgColor, setBgColor] = useState(process.env.REACT_APP_LOGIN_BG_COLOR);
  const { t, i18n} = useTranslation();

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required('Phone is required')
      .matches(/^\d+$/, 'Number only!')
      .min(9, 'Minimum 9 digit numbers')
      .max(13, 'Maximum 13 digit numbers'),
  });

  const defaultValues = {
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, handleSubmit, reset } = methods;

  const values = watch();

  const configureCaptcha = () => {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          firebaseAuth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: async () => {
              setSubmit(true);
              const appVerifier = window.recaptchaVerifier;
              const phoneNumber = country + values.phone;

              try {
                const confirmationResult = await signInWithPhoneNumber(
                  firebaseAuth,
                  phoneNumber,
                  appVerifier
                );
                window.confirmationResult = confirmationResult;
                enqueueSnackbar('Successfully send OTP code.', {
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                });
                setGenerate(true);
              } catch (error) {
                // console.log('erroror', error?.message);
                if (error?.message.toString().includes('too-many-request')) {
                  enqueueSnackbar('Too many request. Please try again later', {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  });
                } else {
                  enqueueSnackbar(
                    'An error occurred while generating OTP code.',
                    {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                    }
                  );
                }
              } finally {
                setSubmit(false);
              }
              return;
            },
          }
        );
      }
    } catch (error) {
      console.warn('Error occured');
    }
  };

  const onSubmit = async () => {
    try {
      setSubmit(true);
      const res = await auth.checkCredentials({ phoneNumber: values.phone });
      if (!res.isExist) {
        enqueueSnackbar('Number not found in our app.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        reset();
      }
      if (res.isExist) {
        setSubmit(true);
        configureCaptcha();
        window.recaptchaVerifier.verify();
      }
    } catch (error) {
      enqueueSnackbar('An error occured while generate OTP.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      console.error('An error occured');
    }
  };

  async function Verify() {
    try {
      setLoading(true);
      await window.confirmationResult.confirm(otp);
      const res = await auth.resetPasswordByPhone({
        phoneNumber: values.phone,
      });
      auth.setDefaultPassword(res.password);
      navigate('/sign-in');
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wrong OTP code. Try again', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const handleChangeCountry = () => {
    if (country === '+60') {
      setCountry('+62');
    } else {
      setCountry('+60');
    }
  };

  const initialGetBgImg =  async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? '' !== '')
        setBgImg(resp.data.slider.bg_img)
    }
  }

  useEffect(()=>{
    initialGetBgImg()
  },[]);

  return (
    <>
       <div
      className='w-full h-screen  relative'
      style={{
        backgroundImage: `url('${bgImg}')`,
        backgroundSize: 'cover',
        // backgroundPosition: "center",
        backgroundRepeat: 'no-repeat',
        opacity:0.5,
        zIndex: "0"
      }}
    >
      <div
      className='w-full h-screen  relative'
      style={{
        opacity: 0.8,
        backgroundColor: bgColor,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex:"1"
      }}
    ></div> 
    </div>
    
    <div
        className={`w-full h-screen flex-col px-5 overflow-hidden ${
          generate ? 'hidden' : 'block'
        } flex justify-center items-center top-0 left-0 absolute z-10`}
      >
        <div className='mb-5'>
         <img
               src={process.env.REACT_APP_LOGO_PATH}
              //src='/images/Produk-Kewangan-Black-Logo.png'
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className='w-36 lg:w-40 object-contain object-center'
            />
         </div>
        <div className='w-full  lg:w-[30rem] sm:w-[25rem]  bg-login-form overflow-hidden rounded-lg shadow-lg  lg:px-10 '>
         
          <div className='flex flex-col items-center'>
            
            <h1 className='text-xl lg:text-2xl font-semibold mt-10 mb-2'>
              Recover Your Password
            </h1>
            <h2 className='text-sm lg:text-md font-semibold text-center mb-2.5'>
              Already have an account?{' '}
              <Link to={`/sign-in`} className='text-blue-500'>
                Sign in here
              </Link>
            </h2>
            <h2 className='text-sm lg:text-md font-semibold text-center mb-7'>
              Click{' '}
              <span
                className='text-blue-500 cursor-pointer'
                onClick={handleChangeCountry}
              >
                here
              </span>{' '}
              if you're {country === '+60' ? 'Indonesian' : 'Malaysian'}
            </h2>
          </div>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 lg:gap-4 w-full mx-auto'>
              <div id='recaptcha-container' />
              <RHFInputAddon name='phone' label='Phone' addon={country} />
              <div className='flex flex-row gap-5 my-10'>
                <ButtonLp
                  type='submit'
                  className='w-40 mx-auto'
                  loading={submited}
                >
                  Continue
                </ButtonLp>
                <Link to={`/${i18n.language}/sign-in`} >
                  <ButtonLp
                  type='button'
                  variant='cancel'
                  className='w-40 mx-auto'
                  >
                    Cancel
                  </ButtonLp> 
                </Link>
               
              </div>
           
            </div>
          </FormProvider>
         
        </div>
      </div>
      
      <div className={`w-full h-screen flex-col px-5 py-10 ${generate ? 'block' : 'hidden'} flex  justify-center items-center top-0 left-0 absolute z-10`}>
        <div className='mb-5'>
          <img
                src={process.env.REACT_APP_LOGO_PATH}
                //src='/images/Produk-Kewangan-Black-Logo.png'
                alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
                className='w-36 lg:w-40 object-contain object-center'
              />
          </div>
        
        <div className='w-full lg:w-[30rem] bg-login-form overflow-hidden rounded-lg shadow-lg px-5 lg:px-10  mx-auto'>
          <div className='flex flex-col items-center'>
           
            <h1 className='text-xl lg:text-2xl font-semibold mt-10 mb-2'>
              OTP Code Confirmation
            </h1>
            <h2 className='text-sm lg:text-md font-semibold  text-center mb-10'>
              We've sent you an OTP code. Please check your message
            </h2>
          </div>

          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => (
              <input {...props} className='!w-10 rounded-lg' />
            )}
            containerStyle='space-x-4 w-full items-center justify-center'
            inputType='number'
          />
          <div className='mt-7 w-full flex justify-center'>
            <div className='flex flex-row gap-2 mb-10'>
              <ButtonLp
                type='button'
                className='w-40 mx-auto'
                onClick={() => Verify()}
                loading={loading}
              >
                {loading ? 'Please wait...' : 'Reset password'}
              </ButtonLp>
              <Link to={`/${i18n.language}/sign-in`} >
                <ButtonLp
                  type='button'
                  variant='cancel'
                  className='w-40 mx-auto'
                >
                  Cancel
                </ButtonLp>
              </Link>
               
            </div>
            
          </div>
         
        </div>
        <div className='w-full flex justify-center  text-sm top-full z-10 mt-5 bottom-0'>
            Copyright © 2024, All Rights Reserved
          </div>
       
      </div>
    </>
   
  );
};

export default ForgotPassword;
