import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/zustand/useAuth';
import useLogStore from '../../hooks/zustand/admin-log/useLogStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleLoginResponse } from '../../lib/api/handleApiResponse';
// components
import { FormProvider, RHFInput, RHFInputAddon } from '../../components/theme2/RHForm';
import ButtonLp from '../../components/theme2/ButtonLp';
import { enqueueSnackbar } from 'notistack';
import ModalAccountLocked from './Modal/Modal.account-locked';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import useHomeSlider from '../../hooks/zustand/useHomeSlider';
import RHLoginTypeDDL from '../../components/theme2/RHForm/RHLoginTypeDDL';
import RHDropDownList from '../../components/theme2/RHForm/RHDropDownList';

const schemaUsePhone = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .matches(/^\d+$/, 'Number only!')
    .min(9, 'Minimum 9 digit numbers')
    .max(13, 'Maximum 13 digit numbers'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(32, 'Password must be at most 32 characters'),
});

const schemaUseEmail = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(32, 'Password must be at most 32 characters'),
});

const defaultValues = {
  phoneNumber: '',
  email: '',
  password: '',
};

const SignIn = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const logStore = useLogStore();
  const homeSliderStore = useHomeSlider();
  const [open, setOpen] = useState(false);
  const attempt = useLocalStorage('withdrawalAttempt');
  const location = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const [bgImg, setBgImg ] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  const [bgColor, setBgColor] = useState(process.env.REACT_APP_LOGIN_BG_COLOR);
  const selectRef = useRef(null);
  const selectLanguageREf = useRef(null);
  const [selectedLoginType, setSelectedLoginType] = useState('phone');
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  
  const initialGetBgImg =  async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? '' !== '')
        setBgImg(resp.data.slider.bg_img)
    }
  }

  useEffect(() => {
    if (location.state?.success === true) {
      enqueueSnackbar('Register successfully. You can login!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    navigate({ ...location, state: { success: false } }, { replace: true });

    //const currLanguage = params.language ?? process.env.REACT_APP_DEFAULT_LANGUAGE;
    i18n.changeLanguage(i18n.language);
    setSelectedLanguage(i18n.language);
    setBgColor(process.env.REACT_APP_LOGIN_BG_COLOR);
    initialGetBgImg();
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const methods = useForm({
    resolver: yupResolver(selectedLoginType === 'email' ? schemaUseEmail : schemaUsePhone),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const cmdLoginType_onChange = (selectedOption) => {
    console.log("Testing");
    console.log('Selected Option:', selectedOption);
    setSelectedLoginType(selectedOption);
  }

  const cmdLanguage_onChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    i18n.changeLanguage(selectedOption);
    console.log("selectedOption : " + selectedOption);
  }

  const onSubmit = async () => {
    const res = await auth.login(
      selectedLoginType === 'phone' ? values.phoneNumber : values.email,
      values.password,
      selectedLoginType
    );
    const validated = handleLoginResponse(res, setError);
    if (
      validated &&
      res?.user?.role !== 'admin' &&
      res?.user?.status !== 'lock'
    ) {
      const memberId = (selectedLoginType === 'phone') ? values.phoneNumber : values.email;
      const maxSuspendAttempts = res?.user?.maxSuspendAttempts ?? process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;
      if (res?.user?.status === 'suspend' && res?.user?.suspendedAttempts >= maxSuspendAttempts) {
        await logStore.createData({
          adminId:'',
          memberId: memberId,
          log: `${memberId} login unsuccessful due to suspended`,
          payload: values,
          action: 'login',
          module: 'member',
          table: 'users'
        });
        navigate(`/dashboard/withdraw`);
      } else {
        await logStore.createData({
          adminId: '',
          memberId: memberId,
          log: `${memberId} login successfully`,
          payload: values,
          action: 'login',
          module: 'member',
          table: 'users'
        });
        navigate(`/dashboard`);
      }
    }
    if (validated && res.user?.role !== '' && res.user?.status === 'lock') {
      setOpen(true);
      reset();
    }
    auth.setDefaultPassword('');
  };

  return (
    <>
    <div
      className='w-full h-screen relative'
      style={{
        backgroundImage: `url('${bgImg}')`,
        opacity: 0.5,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex:"0"
      }}
    >

    <div
      className='w-full h-screen relative'
      style={{
        opacity: 0.8,
        backgroundColor: bgColor,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex:"1"
      }}
    >

    </div>
    </div>  
    
    

      <div className='w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 z-10 '>
        <div className='flex lg:w-[100rem] sm:w-full justify-center items-center  p-5 lg:px-10 py-10 lg:py-14 mx-auto'>
          <img 
               src={process.env.REACT_APP_LOGO_PATH}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className='w-36 lg:w-40 object-contain object-center'
            />
        </div>
        <div className='w-full lg:w-[35rem] sm:w-[24rem] bg-login-form overflow-hidden rounded-20 shadow-lg lg:p-5 lg:px-2 py-3 lg:py-2 mx-auto'>
          <div className='mb-3 flex justify-end'>
            <RHDropDownList 
            ref={selectLanguageREf}
            defaultValue={selectedLanguage} 
            optionLists = {[
                {label:"EN", value: "en"},
                {label:"MY", value: "my"},
                {label:"CN", value: "cn"},
                {label:"JA", value: "ja"}
              ]}
              onChange={cmdLanguage_onChange}
            />
          </div>
          <div className='w-2/3 lg:ml-20 '>
            <div className='flex flex-col items-center'>
              <h1 className='text-4xl font-bold'>
                {t("home.signin.login")}
              </h1>
            
            </div>
            {auth.defaultPassword !== '' ? (
              <div className='w-full bg-c-green/50 p-3 mb-5 rounded-lg'>
                <p className='text-sm font-semibold'>
                  Here's your new password :{' '}
                  {auth.defaultPassword ? auth.defaultPassword : null}
                </p>
              </div>
            ) : null}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-4 lg:gap-8 w-full mx-auto'>
                {selectedLoginType === 'phone' ? (
                  <div>
                    <div className='flex justify-between items-center mb-2'>
                      <label className='text-sm font-medium text-gray-900'>
                        { t("home.signin.username") }
                      </label>
                    </div>
                    <div className='flex flex-row'>
                      <div className='w-3/4'>
                        <RHFInputAddon name='phoneNumber' addon='+60' />
                      </div>
                      <div className='flex items-center justify-center w-1/4'>
                      <RHLoginTypeDDL defaultValue="phone" ref={selectRef} onChange={cmdLoginType_onChange}  />
                      </div>
                    </div>
                    
                  </div>
                ) : (
                  <div>
                    <div className='flex justify-between items-center mb-2'>
                      <label className='text-sm font-medium text-gray-900'>
                        { t("home.signin.username") }
                      </label>
                    </div>
                    <div className='flex flex-row'>
                      <div className='w-3/4'>
                        <RHFInput name='email' placeHolder="Email" />
                      </div>
                      <div className='flex items-center justify-center w-1/4'>
                      <RHLoginTypeDDL defaultValue="email" ref={selectRef} onChange={cmdLoginType_onChange}  />
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <div className='flex justify-between items-center mb-2'>
                    <label className='text-sm font-medium'>
                      { t("home.signin.password") }
                    </label>
                    <Link
                      to={`/${i18n.language}/forgot-password`} 
                      className='text-sm underline font-bold'
                      reloadDocument
                    >
                      {t("home.signin.forgetpassword")}
                    </Link>
                  </div>
                  <RHFInput name='password' type='password' />
                </div>

                  <ButtonLp
                    type='submit'
                    className='w-40 mx-auto mt-3'
                    loading={isSubmitting}
                  >
                    { t("home.signin.loginNow")}
                  </ButtonLp>

                
              </div>
            </FormProvider>
            <div>
            <h1 className='text-sm lg:text-md font-semibold text-center mt-5'>
                { t("home.signin.newhere") }
                <Link to={`/sign-up`} className='font-bold underline'>
                  { t("home.signin.createanaccount") }
                </Link>
              </h1>
            </div>
            <div className='mt-2 text-center'>
              <Link to='/' className='font-bold underline text-sm'>
                { t("home.signin.backtohome") }
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className='text-sm mt-3'>
            Copyright © 2024, All Rights Reserved
          </div>
          
        </div>
      </div>
      <ModalAccountLocked open={open} close={() => setOpen(false)} />
</>

    
  );
};

export default SignIn;
