import React, { forwardRef, useEffect, useState } from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#193261',
    color: '#FFFFFF',
    width: "75px",
    height: "30px",
    fontSize:"16px",
    '@media (max-width: 640px)': {
      width: "75px", // Adjust for smaller screens
      height: "20px", // Adjust for smaller screens
      fontSize:"12px",
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#193261',
    borderRadius: '0.375rem',
    width: "75px",
    height: "30px",
    maxWidth: "100%",
    boxSizing: "border-box",
    fontSize:"16px",
    '@media (max-width: 640px)': {
      width: "75px", // Adjust for smaller screens
      height: "20px", // Adjust for smaller screens
      fontSize:"12px",
    },
    
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#FFFFFF',
    width: "75px",
    height: "30px"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const RHDropDownList = forwardRef(({ defaultValue, onChange, optionLists }, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = optionLists.find(option => option.value === defaultValue);
      setSelectedOption(defaultOption || null);
    } else {
      setSelectedOption(optionLists[0] || null);
    }
  }, [defaultValue]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    if (onChange) {
      onChange(selected ? selected.value : null);
    }
  };

  return (
    <div className='flex items-center justify-center'>
      <Select
        ref={ref}
        name="dropdownlist"
        options={optionLists}
        styles={customStyles}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  );
});

export default RHDropDownList;