import {  Button, Heading } from "../../components/theme2";
import React, {useState, useRef, useCallback, useEffect} from "react";
import useEmblaCarousel from "embla-carousel-react";
import { Link } from "react-router-dom";
import  useAuth  from "../../hooks/zustand/useAuth";
import  i18next  from "i18next";
import { useTranslation } from "react-i18next";
import TradingViewWidget from "components/theme2/TradingViewWidget";


export default function InvestSection() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    containScroll: "trimSnaps",
    loop: false,
  });
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const [tabSelected, setTabSelected] = useState("crypto");

  const isLogin = useAuth();

  const BTCReff = useRef(null);
  const ETHReff = useRef(null);
  const DOTReff = useRef(null);
  const HTReff = useRef(null);
  const XRPReff = useRef(null);
  const DOGEReff = useRef(null);
  const BNBReff = useRef(null);
  const TRXReff = useRef(null);
  const SOLReff = useRef(null);

  const SPXReff = useRef(null);
  const VIXReff = useRef(null);
  const DXYReff = useRef(null);
  const DAXReff = useRef(null);
  const HSIReff = useRef(null);
  const CAC40Reff = useRef(null);
  const NASDAQReff = useRef(null);
  const NIKKEIReff = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <>
      {/* main frame section */}
      <div className="mt-[5.63rem] sm:pt-[40rem] relative flex flex-col items-center">
        <div className="container-xs flex flex-col items-center gap-[0.25rem] px-[0.19rem] lg:gap-[0.25rem] lg:p-[0.06rem] md:gap-[0.19rem] md:p-[0.06rem] md:px-[0.06rem] sm:gap-[0.13rem]">
          <div className="mr-[0.00rem] flex flex-col items-center gap-[0.00rem] lg:mr-0 md:mr-0">
            <Heading size="headings" as="h3" className="!font-bold !text-black-900 lg:text-[0.06rem] md:text-[0.06rem]">
              { t("home.section2.trusted") }
            </Heading>
            <Heading
              size="headingxl"
              as="h4"
              className="!text-primary lg:text-[0.13rem] md:text-[0.13rem] sm:text-[0.06rem] sm:text-center"
            >
              { t("home.section2.title") }
            </Heading>
          </div>
          <div className="flex sm:gap-[1.00rem] sm:pt-10">
            <Button  
              color={(tabSelected === 'crypto')? "black_900" : "gray_100" }
              size="lg" 
              shape="round" 
              className="min-w-[0.56rem]"
              onClick={ () => {
                setTabSelected("crypto")
              }}
            >
              { t("home.section2.subtitle.Crypto")}
            </Button>
            <Button 
              color={(tabSelected === 'shareIndex')? "black_900" : "gray_100" }
              size="lg" 
              shape="round" 
              className="min-w-[0.75rem]"
              onClick = {() => {
                setTabSelected("shareIndex");
              }}
            >
              { t("home.section2.subtitle.Share Index")}
            </Button>
          </div>
        </div>
        <div className="container-xs mx-auto mt-[3.38rem] px-10 ">
          <div>
            {tabSelected === "crypto" && (
              <div ref={emblaRef} className="overflow-hidden">
                <div className="flex py-10 gap-4 justify-center ">
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/btc"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:BTCUSD"
                        containerRef={BTCReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/eth"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:ETHUSD"
                        containerRef={ETHReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/dot"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:DOTUSD"
                        containerRef={DOTReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/ht"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="CRYPTO:HTUSD"
                        containerRef={HTReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/xrp"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:XRPUSD"
                        containerRef={XRPReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/doge"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:DOGEUSD"
                        containerRef={DOGEReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/bnb"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:BNBUSD"
                        containerRef={BNBReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/trx"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:TRXUSD"
                        containerRef={TRXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/sol"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:SOLUSD"
                        containerRef={SOLReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tabSelected === "shareIndex" && (
              <div ref={emblaRef} className="overflow-hidden">
                <div className="flex py-10 gap-4 justify-center ">
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/sp500"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="FRED:SP500"
                        containerRef={SPXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/dxy"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="CAPITALCOM:DXY"
                        containerRef={DXYReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/vix"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="CAPITALCOM:VIX"
                        containerRef={VIXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/dax"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="XETR:DAX"
                        containerRef={DAXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/hsi"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="HSI:HSI"
                        containerRef={HSIReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/cac40"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="INDEX:CAC40"
                        containerRef={CAC40Reff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/nikkei"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="FRED:NIKKEI225"
                        containerRef={NIKKEIReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/investment/nasdaq"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="NASDAQ:NDX"
                        containerRef={NASDAQReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
